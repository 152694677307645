import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isClickedOnLogin: false,
    isUserExist: false,
    token: "",
    userDetails: [],
    profileLoading: false,
    triggered: false,
    profile: "",
  },
  reducers: {
    onLogin: () => {},
    clickedOnLogin: (state, action) => {
      state.isClickedOnLogin = action.payload.clicked;
    },
    letCheckUserExist: (state, action) => {
      state.isUserExist = action.payload.isExist;
    },
    onSetToken: (state, action) => {
      state.token = action.payload;
    },
    onGetUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    onLoading: (state, action) => {
      state.profileLoading = action.payload;
    },
    triggeredProfileDetails: (state, action) => {
      state.triggered = !state.triggered;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
});
export const authAction = authSlice.actions;
export default authSlice.reducer;
