import React from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

const List = (props) => {
  return (
    <Link to={props.path} onClick={props.onClick} className={styles.list}>
      <span className={styles.list__icon}>{props.icon}</span>
      <span style={{ color: `${props.color}` }} className={styles.list__name}>
        {props.name}
      </span>
    </Link>
  );
};

export default List;
