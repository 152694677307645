import React from "react";
import styles from "./index.module.scss";
import Container from "../../shared/Container";
import Top from "./Top";
import ToFrom from "./ToFrom";
import Table from "./Table";
import PayMethod from "./PayMethod";
import Bottom from "./Bottom";
import SocialPlatforms from "./SocialPlatforms";
import Charges from "./Charges";

const Invoice = () => {
  return (
    <Container>
      <div className={styles.invoice}>
        <Top />
        <ToFrom />
        <Table />
        <Charges />
        <PayMethod />
        <SocialPlatforms />
        <p>This is digitaly created Invoice and is valid without Signature or Seal</p>
        <Bottom />
      </div>
    </Container>
  );
};

export default Invoice;
