import React from "react";
import styles from "./index.module.scss";
import { useLocation, useParams } from "react-router-dom";
import Card from "../../components/Card";
import Container from "../../shared/Container";
import Angle from "../../widgets/Angle";
import { useTranslation } from "react-i18next";

const SeeAllLists = () => {
  const { state } = useLocation();
  const params = useParams();
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <div className={styles.allList}>
          <h1 className={styles.allList__title}>
            {t("home")} <Angle />
            {params.type.replace("-", " ")}
          </h1>

          <div className={styles.allList__body}>
            {state?.allLists?.map((item) => {
              return (
                <Card
                  id={item.id}
                  key={item.id}
                  title={item.title}
                  src={item.image_id}
                  price={item.discounted_price}
                  original_price={item.outlay_price}
                  discount={item.discount_percnt}
                  masterId={item}
                  subId={item.subcat_id}
                  data={item}
                />
              );
            })}
          </div>
        </div>
      </Container>
    </>
  );
};

export default SeeAllLists;
