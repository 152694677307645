import Swal from "sweetalert2";

const SweetAlert = (type, title) => {
  const iconType = ["success", "error", "warning", "info", "question"].includes(type) ? type : "error";
  Swal.fire({
    position: "center",
    icon: iconType,
    title: title,
    showConfirmButton: false,
    timer: 1500,
  });
};

export default SweetAlert;
