import React from "react";
import logo from "../../assets/logo.png";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";

const Logo = () => {
  const navigate = useNavigate();
  const onNavigateToHome = () => {
    navigate("/");
  };
  return (
    <div onClick={onNavigateToHome} className={styles.logo}>
      <img src={logo} alt="logo" />
    </div>
  );
};

export default Logo;
