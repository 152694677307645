import React, { useLayoutEffect, useState } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { onFavrtSave } from "../../services/server";
import SweetAlert from "../../shared/SweetAlert";
import { productsActions } from "../../utils/reducers/product";
import { useLocation } from "react-router-dom/dist";
import { FaStar } from "react-icons/fa";
import { uiActions } from "../../utils/reducers/ui";

const Card = ({ title, src, price, original_price, discount, id, subId, masterId, data }) => {
  const [isFavrt, setIsFavrt] = useState(false);

  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const location = JSON.parse(localStorage.getItem("locDet"));
  const isFav = useSelector((state) => state.products.isFavrtExist);
  const token = useSelector((state) => state.auth.token);
  // const loading = useSelector((state) => state.ui.skelatonLoading);
  const allFavrtList = useSelector((state) => state.products.wishlist);

  const onGetProductDetails = () => {
    if (pathname === "/wishlists") {
      const path = generatePath("/:location/wishlists/:subCatName/:subId/:name/:prodId", {
        location: location.city,
        subId: btoa(data.subcategory),
        subCatName: data.subcategory_name,
        name: title?.replaceAll(" ", "-"),
        prodId: btoa(data.id),
      });
      navigate(path);
    } else if (!params.location) {
      const path = generatePath("/:location/packages/:subCatName/:subId/:name/:prodId", {
        location: location.city,
        subId: btoa(data.subcat_id),
        subCatName: data.subcat_name,
        name: title?.replaceAll(" ", "-"),
        prodId: btoa(data.id),
      });
      navigate(path);
    } else {
      if (params.offerName !== undefined) {
        const path = generatePath("/:location/offers/:offerName/:offerId/:name/:prodId", {
          location: params.location,
          offerName: params.offerName,
          offerId: btoa(params.offerId),
          name: title.replaceAll(" ", "-"),
          prodId: btoa(id),
        });

        navigate(path);
      } else if (params.masterCat !== undefined && params.subCat !== undefined) {
        const path = generatePath("/:location/:masterCat/:masterId/:subCat/:subId/:childCat/:childId/:name/:prodId", {
          location: params.location,
          masterCat: params.masterCat,
          masterId: btoa(params.masterId),
          subCat: params.subCat,
          subId: btoa(params.subId),
          childCat: params.childCat,
          childId: btoa(params.childId),
          name: title.replaceAll(" ", "-"),
          prodId: btoa(id),
        });
        navigate(path);
      } else if (params.subCatName) {
        const path = generatePath("/:location/searches/:subCatName/:subId/:childCat/:childId/:name/:prodId", {
          location: params.location,
          // subId: btoa(subId),
          subCatName: params.subCatName,
          subId: btoa(params.subId),
          childCat: params.childCat,
          childId: btoa(params.childId),
          name: title.replaceAll(" ", "-"),
          prodId: btoa(id),
        });
        navigate(path);
      } else {
        const path = generatePath("/:location/:masterCat/:masterId/:subId/:name/:prodId", {
          location: params.location,
          masterCat: params.masterCat,
          masterId: btoa(masterId),
          subId: btoa(subId),
          name: title.replaceAll(" ", "-"),
          prodId: btoa(id),
        });
        navigate(path);
      }
    }
  };

  const onFavrtMark = async () => {
    if (token) {
      const isFav = allFavrtList.find((item) => item.id === id);

      const params = {
        package_id: id,
        model_type: "package",
        is_fav: !isFav ? "true" : `${!isFav.is_fav}`,
        user_type: "1",
      };

      const data = await onFavrtSave(params, token);

      if (data.status) {
        SweetAlert("success", !isFav ? "Added To Favourite List" : "Removed From Favorite List");
        dispatch(productsActions.isExist());
      }
    } else {
      dispatch(
        uiActions.isLoginModalOpen({
          open: true,
        })
      );
    }
  };

  useLayoutEffect(() => {
    const isFav = allFavrtList.find((item) => item.id === id);
    if (isFav) {
      setIsFavrt(true);
    } else {
      setIsFavrt(false);
    }
    // eslint-disable-next-line
  }, [isFav, allFavrtList]);

  return (
    <>
      <div className={styles.card}>
        <div className={styles.card__imageContainer}>
          {/* {loading ? (
            <Skeleton variant="rectangular" height={200} className={styles.card__imageContainer} />
          ) : ( */}
          <div className={styles.card__imageContainer__svg}>
            <img src={src} alt={title} />
          </div>
          {/* )} */}

          {/* {loading ? (
            <Skeleton
              variant="rectangular"
              height={40}
              style={{
                left: `${i18n.language === "ar" ? "0.7rem" : ""}`,
                right: `${i18n.language === "en" ? "0.7rem" : ""}`,
                backgroundColor: "#00000045",
                margin: "0 0 10px",
              }}
              className={styles.card__imageContainer__price}
            />
          ) : ( */}
          <div
            style={{ left: `${i18n.language === "ar" ? "0.7rem" : ""}`, right: `${i18n.language === "en" ? "0.7rem" : ""}` }}
            className={styles.card__imageContainer__price}>
            ₹{price}
          </div>
          {/* )} */}
        </div>
        <div onClick={onFavrtMark} className={styles.card__favorite}>
          {/* {loading ? (
            <Skeleton variant="rectangular" height={20} style={{ borderRadius: 20 }} className={styles.card__favorite__svg} />
          ) : ( */}
          <svg
            style={{ fill: `${isFavrt ? "red" : "gray"}` }}
            xmlns="http://www.w3.org/2000/svg"
            className={styles.card__favorite__svg}
            viewBox="0 0 24 24"
            fill="#000000">
            <path d="M12 20a1 1 0 0 1-.437-.1C11.214 19.73 3 15.671 3 9a5 5 0 0 1 8.535-3.536l.465.465.465-.465A5 5 0 0 1 21 9c0 6.646-8.212 10.728-8.562 10.9A1 1 0 0 1 12 20z"></path>
          </svg>
          {/* )} */}
        </div>

        <div className={styles.card__content}>
          {/* {loading ? (
            <Skeleton
              variant="rectangular"
              height={20}
              style={{ textAlign: `${i18n.language === "ar" ? "right" : ""}`, borderRadius: "30px", margin: "5px 0" }}
              className={styles.card__content__brand}
            />
          ) : ( */}
          <div style={{ textAlign: `${i18n.language === "ar" ? "right" : ""}` }} className={styles.card__content__brand}>
            <del>₹{original_price}</del>
          </div>
          {/* )} */}

          {/* {loading ? (
            <Skeleton
              variant="rectangular"
              height={20}
              style={{ textAlign: `${i18n.language === "ar" ? "right" : ""}`, borderRadius: "30px", margin: "5px 0" }}
              className={styles.card__content__brand}
            />
          ) : ( */}
          <div style={{ textAlign: `${i18n.language === "ar" ? "right" : ""}` }} className={styles.card__content__productName}>
            {title}
          </div>
          {/* )} */}

          {/* {loading ? (
            <Skeleton
              variant="rectangular"
              height={30}
              style={{ textAlign: `${i18n.language === "ar" ? "right" : ""}`, borderRadius: "30px", margin: "5px 0" }}
              className={styles.card__content__brand}
            />
          ) : ( */}
          <div
            style={{ flexDirection: `${i18n.language === "ar" ? "row-reverse" : ""}` }}
            className={styles.card__content__rating}>
            <p className={styles.card__content__rating__star}>
              {[1, 2, 3, 4, 5].map((star, index) => {
                return (
                  <FaStar
                    className={styles.card__content__rating__star__icon}
                    key={star}
                    color={index <= Math.ceil(data?.rating) ? "#fc0" : "#e9e9e9"}
                  />
                );
              })}
              <span>({data?.reviews})</span>
            </p>
            <p className={styles.card__content__rating__percent}>{discount}% off</p>
          </div>
          {/* )} */}
        </div>

        <div className={styles.card__buttonContainer}>
          {/* {loading ? (
            <Skeleton
              variant="rectangular"
              height={40}
              style={{ border: "none" }}
              className={styles.card__buttonContainer__buyButton}
            />
          ) : ( */}
          <button onClick={onGetProductDetails} className={styles.card__buttonContainer__buyButton}>
            {i18n.language === "ar" ? "احجز الآن" : "Book Now"}
          </button>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default Card;
