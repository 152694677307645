import React from "react";
import star from "../../assets/star.webp";
import medal from "../../assets/medal.webp";
import team from "../../assets/teamwork.webp";
import time from "../../assets/time.webp";
import ChooseCard from "./ChooseCard";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import Container from "../../shared/Container";

const chooseUs = [
  { id: 1, title: "Create New and innovative ways to amaze you", src: star, rtl: "ابتكر طرقًا جديدة ومبتكرة لتذهلك" },
  { id: 2, title: "No compromising with the quality", src: medal, rtl: "لا يوجد حل وسط مع الجودة" },
  { id: 3, title: "Assured on-time services", src: time, rtl: "خدمات مضمونة في الوقت المحدد" },
  { id: 4, title: "our dedicated team is always at your help", src: team, rtl: "فريقنا المتفاني هو دائما في مساعدتكم" },
];

const WhyChooseUs = () => {
  const { t, i18n } = useTranslation();
  return (
    <Container>
      <div className={styles.whyChooseUs}>
        <div className={styles.whyChooseUs__title}>
          <h1 className={styles.whyChooseUs__title__h1} style={{ fontFamily: `${i18n.language === "ar" ? "SF-Medium" : ""}` }}>
            {t("whychooseus.title")}
          </h1>
          <p
            className={styles.whyChooseUs__title__p}
            style={{ color: "#707070", fontFamily: i18n.language === "ar" ? "SF-Medium" : "" }}>
            {t("whyChoosetitle")}
          </p>
        </div>
        <div className={styles.whyChooseUs__content}>
          <div className={styles.whyChooseUs__content__left}>
            {chooseUs.map((item) => (
              <ChooseCard key={item.id} title={i18n.language === "ar" ? item.rtl : item.title} src={item.src} />
            ))}
          </div>
          <div className={styles.whyChooseUs__content__right}>
            <p
              style={{ textAlign: `${i18n.language === "ar" ? "right" : "left"}` }}
              className={styles.whyChooseUs__content__right__desc}>
              {t("whychooseus.para1")}
            </p>
            <p
              style={{ textAlign: `${i18n.language === "ar" ? "right" : "left"}` }}
              className={styles.whyChooseUs__content__right__desc}>
              {t("whychooseus.para2")}
            </p>
            <p
              style={{ textAlign: `${i18n.language === "ar" ? "right" : "left"}` }}
              className={styles.whyChooseUs__content__right__desc}>
              {t("whychooseus.para3")}
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WhyChooseUs;
