import React from "react";
import Title from "../Title";
import styles from "./index.module.scss";

const NeedToKnow = ({ needToKnow, title }) => {
  return (
    <div className={styles.needToKnow}>
      <Title title={title} />
      <div className={styles.needToKnow__content} dangerouslySetInnerHTML={{ __html: needToKnow }}></div>
    </div>
  );
};

export default NeedToKnow;
