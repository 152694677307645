import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "products",
  initialState: { allProducts: [], wishlist: [], isFavrtExist: false },
  reducers: {
    allProducts: (state, action) => {
      state.allProducts = action.payload.products;
    },
    allWishList: (state, action) => {
      state.wishlist = action.payload.favrtList;
    },
    isExist: (state, action) => {
      state.isFavrtExist = !state.isFavrtExist;
    },
  },
});

export const productsActions = productSlice.actions;
export default productSlice.reducer;
