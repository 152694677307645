import React from "react";
import styles from "./index.module.scss";
import { FaHeart, FaUser } from "react-icons/fa";
import { HiClipboardCopy, HiOutlineLogout } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { uiActions } from "../../utils/reducers/ui";
import { authAction } from "../../utils/reducers/auth";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardMenu = [
    { id: 1, icon: <FaUser size={20} color="white" />, name: t("profile.prof") },
    { id: 2, icon: <FaHeart size={20} color="white" />, name: t("profile.wishlists") },
    { id: 3, icon: <HiClipboardCopy size={24} color="white" />, name: t("profile.bookings") },
    // { id: 4, icon: <HiClock size={24} color="white" />, name: "Past Bookings" },
    { id: 5, icon: <HiOutlineLogout size={24} color="white" />, name: t("profile.logout") },
  ];

  const closedDashboardModal = () => {
    dispatch(
      uiActions.isDashboardOpen({
        isOpen: false,
        isClosed: false,
      })
    );
  };

  const getDynamicFunctionality = (id) => {
    if (id === 1) {
      dispatch(
        uiActions.isProfileOpen({
          open: true,
        })
      );
    }
    if (id === 2) {
      navigate("/wishlists");
    }
    if (id === 3) {
      navigate("/bookings");
    }
    if (id === 4) {
      navigate("/past-bookings");
    }
    if (id === 5) {
      navigate("/");
      dispatch(
        uiActions.userIsLogout({
          loggedIn: false,
        })
      );
      localStorage.removeItem("userDetails");
      localStorage.removeItem("picture");
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      dispatch(authAction.onSetToken(""));
      dispatch(
        authAction.clickedOnLogin({
          clicked: false,
        })
      );
    }

    dispatch(
      uiActions.isDashboardOpen({
        isOpen: false,
        isClosed: false,
      })
    );
  };

  return (
    <>
      <div onClick={closedDashboardModal} className={styles.overlay}></div>
      <div
        style={{ right: i18n.language === "ar" ? "" : "20px", left: i18n.language === "ar" ? "20px" : "" }}
        className={styles.content}>
        <p
          style={{ right: i18n.language === "ar" ? "" : "28px", left: i18n.language === "ar" ? "28px" : "" }}
          className={styles.content__triangle}></p>
        <ul className={styles.content__ul}>
          {dashboardMenu.map((item) => (
            <li key={item.id} onClick={() => getDynamicFunctionality(item.id)} className={styles.content__ul__li}>
              <p className={styles.content__ul__li__icon}>{item.icon}</p>
              <p className={styles.content__ul__li__name}>{item.name}</p>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Dashboard;
