import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";
import prodBanner from "../../assets/prodBanner.svg";
import ProductView from "../../components/ProductView";
import Button from "../../widgets/Button";
import Packages from "../../components/Packages";
import { endpoints } from "../../services/endpoints";
import axios from "axios";
import { useParams } from "react-router-dom";
import CancellationPolicy from "./CancellationPolicy";
import RefundPolicy from "./RefundPolicy";
import NeedToKnow from "./NeedToKnow";
import Arrangements from "./Arrangements";
import TermAndCondition from "./Terms&Condition";
import Exclusion from "./Exclusion";
import Faqs from "./FAQ";
import Videos from "./Videos";
import Details from "./Details";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../utils/reducers/ui";
import { Skeleton } from "@mui/material";
import BookSlot from "./BookSlot";
import Container from "../../shared/Container";
import Reviews from "./Reviews";
import Angle from "../../widgets/Angle";

const ProductDetails = () => {
  const [scroll, setScroll] = useState(0);
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allPackage, setAllPackage] = useState([]);

  const { i18n, t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();

  let decodeProdId = atob(params.prodId);
  let relatedPackageId = params.offerId ? atob(params.offerId) : atob(params.subId);

  const detailsLoad = useSelector((state) => state.ui.detailsLoading);

  useEffect(() => {
    const handleScroll = () => {
      const scrollVal = document.documentElement.scrollTop;
      setScroll(scrollVal);
    };

    //event listener
    window.addEventListener("scroll", handleScroll);

    //component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(
      uiActions.isDetailsLoad({
        loading: true,
      })
    );
    const url = endpoints.deatils.getDetails + decodeProdId;
    axios
      .get(url)
      .then((res) => {
        if (res.data.status) {
          setDetails(res.data.body[0]);
          dispatch(
            uiActions.isDetailsLoad({
              loading: false,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          uiActions.isDetailsLoad({
            loading: false,
          })
        );
      });
  }, [decodeProdId, dispatch]);

  const getAllRelatedPackage = () => {
    setLoading(true);
    const url = endpoints.home.packageBySubCategory + `${params.subCat ? atob(relatedPackageId) : relatedPackageId}`;
    dispatch(
      uiActions.skelatonLoadingCurrently({
        loading: true,
      })
    );
    axios
      .get(url)
      .then((res) => {
        if (res.data.status) {
          const val = res.data.body;
          setAllPackage(val);
          dispatch(
            uiActions.skelatonLoadingCurrently({
              loading: false,
            })
          );
          setLoading(false);
        } else {
          dispatch(
            uiActions.skelatonLoadingCurrently({
              loading: false,
            })
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "error");
        dispatch(
          uiActions.skelatonLoadingCurrently({
            loading: false,
          })
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllRelatedPackage();
    // eslint-disable-next-line
  }, []);

  const reviewRef = useRef();
  const arrangementsRef = useRef();
  const overviewRef = useRef();
  const videoRef = useRef();
  const exclusionRef = useRef();
  const checkoutRef = useRef();

  const review = () => {
    reviewRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const viewArrangements = () => {
    arrangementsRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const Overviews = () => {
    overviewRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const showVideo = () => {
    videoRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const viewExclusion = () => {
    exclusionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onCheckoutView = () => {
    checkoutRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {detailsLoad ? (
        <Skeleton variant="rectangular" height={400} className={styles.banner} />
      ) : (
        <div className={styles.banner}>
          <img
            src={details?.banner_image_id !== "https://admin.xperienceit.in/uploads" ? details.banner_image_id : prodBanner}
            alt={details.banner_image_id}
          />
        </div>
      )}
      <Container>
        <div className={styles.title}>
          {params.subCatName ? (
            <p>
              {t("home")} <Angle />
              {params.subCatName.replaceAll("-", " ")} <Angle />
              {params.childCat.replaceAll("-", " ")}
              <Angle />
              {params.name.replaceAll("-", " ")}
            </p>
          ) : (
            <>
              {!params.offerName ? (
                <>
                  {params.subCat === undefined ? (
                    <>
                      {detailsLoad ? (
                        <Skeleton variant="rectangular" height={45} style={{ margin: "5px 0" }} />
                      ) : (
                        <p>
                          {t("home")}
                          <Angle />
                          {params.masterCat.replaceAll("-", " ")}
                          <Angle />
                          {params.name.replaceAll("-", " ")}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {detailsLoad ? (
                        <Skeleton variant="rectangular" height={45} style={{ margin: "5px 0" }} />
                      ) : (
                        <p>
                          {t("home")} <Angle />
                          {params.masterCat.replaceAll("-", " ")}
                          <Angle />
                          {params.subCat.replaceAll("-", " ")}
                          <Angle />
                          {params.childCat.replaceAll("-", " ")}
                          <Angle />
                          {params.name.replaceAll("-", " ")}
                        </p>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {detailsLoad ? (
                    <Skeleton variant="rectangular" height={45} style={{ margin: "5px 0" }} />
                  ) : (
                    <p>
                      {t("home")} <Angle />
                      {params.offerName.replaceAll("-", " ")}
                      <Angle />
                      {params.name.replaceAll("-", " ")}
                    </p>
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className={styles.productDetails}>
          <div className={styles.productDetails__left}>
            <ProductView gallery={details?.gallery} all={details} />
          </div>
          <div className={styles.productDetails__right}>
            <div>
              <BookSlot ref={checkoutRef} details={details} detailsLoad={detailsLoad} />
            </div>
            {details.content && <Details ref={overviewRef} content={details.content} title={t("details.overview")} />}
            {details.cancellation && (
              <CancellationPolicy cancellationPolicy={details.cancellation} title={t("details.cancellation")} />
            )}
            {details.refund_policy && <RefundPolicy refundPolicy={details.refund_policy} title={t("details.refund")} />}
            {details.points_note && <NeedToKnow needToKnow={details.points_note} title={t("details.needToKnow")} />}
            {details.arrangments && (
              <Arrangements ref={arrangementsRef} arrangements={details.arrangments} title={t("details.arrangements")} />
            )}
            {details.termcondition && <TermAndCondition tAndC={details.termcondition} title={t("details.terms")} />}
            {details.faqs && <Faqs faqs={details.faqs} title={t("details.faqs")} />}
            {details.exclusion && <Exclusion ref={exclusionRef} exclusion={details.exclusion} title={t("details.exclusion")} />}
            {details.video && <Videos ref={videoRef} video={details.video} title={t("details.video")} />}
            <Reviews ref={reviewRef} allData={details} />
          </div>
        </div>
      </Container>
      {allPackage.length > 0 && (
        <Packages loading={loading} heading={i18n.language === "ar" ? "حزم مثيرة" : "Related Packages"} items={allPackage} />
      )}
      <div className={styles.bottomDet}>
        <ul className={styles.bottomDet__left}>
          {details.content && <li onClick={Overviews}>{t("details.overview")}</li>}
          {details.arrangments && <li onClick={viewArrangements}>{t("details.arrangements")}</li>}
          {details.exclusion && <li onClick={viewExclusion}>{t("details.exclusion")}</li>}
          <li onClick={review}>{t("details.reviews")}</li>
          {details.video && <li onClick={showVideo}>{t("details.video")}</li>}
        </ul>
        {scroll >= 500 && (
          <div className={styles.bottomDet__right}>
            <h2 className={styles.bottomDet__right__full}>
              ₹{details.discounted_price}
              <span>/</span>
              <del>₹{details.outlay_price}</del>
            </h2>
            <div className={styles.bottomDet__right__btn}>
              <Button
                onClick={onCheckoutView}
                border="1px solid #15124b"
                background="#15124b"
                color="#fff"
                name={t("details.checkout")}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductDetails;
