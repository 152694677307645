import { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import styles from "./index.module.scss";
import Button from "../../widgets/Button";

const ReactCropper = ({ onModalClose, imgURL, setCroppedImgUrl, onSaveHandler, setCroppedImg }) => {
  const cropperRef = useRef(null);

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImgUrl(cropper.getCroppedCanvas().toDataURL());

    cropper.getCroppedCanvas().toBlob((blob) => {
      setCroppedImg(blob);
    });
  };

  return (
    <>
      <Cropper
        src={imgURL}
        initialAspectRatio={1 / 1}
        guides={false}
        crop={onCrop}
        ref={cropperRef}
        viewMode={1}
        className={styles.Cropper}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        responsive={true}
        autoCropArea={1}
        aspectRatio={1 / 1}
        checkOrientation={false}
      />
      <div className={styles.btn}>
        <Button onClick={onModalClose} background="gray" border="1px solid gray" name="Cancel" />
        <Button onClick={onSaveHandler} name="Save" />
      </div>
    </>
  );
};
export default ReactCropper;
