import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import Container from "../../shared/Container";

const BestSellerZone = ({ items }) => {
  const { i18n } = useTranslation();
  return (
    <Container>
      <div className={styles.bestSellerZone}>
        {items.map((item) => (
          <div key={item.name} className={styles.bestSellerZone__seller}>
            <img src={item.image} alt={item.name} />
            <p className={styles.bestSellerZone__seller__title}>{i18n.language === "ar" ? item.rtlName : item.name}</p>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default BestSellerZone;
