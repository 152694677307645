import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import NeedHelp from "./NeedHelp";
import ContactUs from "./ContactUs";
import ForServices from "./ForServices";
import ForStore from "./ForStore";
import baloon from "../../assets/animation/heartBaloon.json";
import { useLocation } from "react-router-dom";
import Container from "../../shared/Container";

const Footer = () => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div style={{ marginTop: `${pathname === "/" ? "100px" : 0}` }} className={styles.footer}>
      {pathname === "/" && (
        <Lottie
          loop={true}
          animationData={baloon}
          className={styles.footer__animation}
          style={{ right: `${i18n.language === "ar" ? "" : "0"}`, left: `${i18n.language === "ar" ? "0" : ""}` }}
        />
      )}
      <Container>
        <div className={styles.footer__supFooter}>
          <div className={styles.footer__supFooter__div}>
            <NeedHelp />
          </div>
          <div className={styles.footer__supFooter__div}>
            <ForServices />
          </div>
          <div className={styles.footer__supFooter__div}>
            <ContactUs />
          </div>
          <div className={styles.footer__supFooter__div}>
            <ForStore />
          </div>
        </div>
      </Container>
      <div className={styles.footer__subFooter}>
        <p>
          {i18n.language === "ar" ? "©بواسطة Skyview Smart Solutions" : "©By Skyview Smart Solutions"}{" "}
          <a href="https://skyviewads.com/" target="_blank" rel="noreferrer">
            ( www.skyviewads.com )
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
