import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import SearchBar from "../SearchBar";
import Logo from "../Logo";
import { FaLocationDot } from "react-icons/fa6";
// import { FaUser } from "react-icons/fa6";
import HeaderMenu from "../HeaderMenu";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../utils/reducers/ui";
import LocationPicker from "../../modals/LocationPicker";
import user from "../../assets/user.png";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../modals/Dashboard";
import menuBar from "../../assets/menu.svg";
import MobileNave from "../MobileNav";
import Profile from "../../modals/Profile";
const DesktopNav = ({ onLoginOpen }) => {
  const { t, i18n } = useTranslation();
  const [showHeader, setShowHeader] = useState(true);
  const location = JSON.parse(localStorage.getItem("locDet"));
  const openModal = useSelector((state) => state.ui.isModalVisible);
  // const prof = JSON.parse(localStorage.getItem("picture"));
  const openDashboard = useSelector((state) => state.ui.openDashboard);
  const openProfile = useSelector((state) => state.ui.openProfile);
  const profile = useSelector((state) => state.auth.profile);
  const loggedIn = useSelector((state) => state.ui.isLoggedIn);
  const locationDet = useSelector((state) => state.ui.cityName);
  const openMenu = useSelector((state) => state.ui.showMenu);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openLocationModal = () => {
    dispatch(
      uiActions.openLocationModal({
        isTrue: true,
      })
    );
  };

  const onClose = () => {
    dispatch(
      uiActions.isProfileOpen({
        open: false,
      })
    );
  };

  const chooseLocation = (city, id) => {
    const locDet = {
      city: city,
      id: id,
    };
    navigate("/");
    localStorage.setItem("locDet", JSON.stringify(locDet));
    dispatch(
      uiActions.chooseCity({
        city: city,
        id: id,
      })
    );
    dispatch(
      uiActions.openLocationModal({
        isTrue: false,
      })
    );
  };
  useEffect(() => {
    if (location) {
      dispatch(
        uiActions.chooseCity({
          city: location.city,
          id: location.id,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  const openDashboardModal = () => {
    dispatch(
      uiActions.isDashboardOpen({
        isOpen: true,
      })
    );
  };
  const onOpenMenuBar = () => {
    dispatch(
      uiActions.showMenu({
        isShoMenu: true,
      })
    );
  };

  useEffect(() => {
    let prevScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 60 && currentScrollY > prevScrollY) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }

      prevScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {openModal && <LocationPicker onClick={chooseLocation} />}
      <header style={{ position: `${showHeader ? "sticky" : ""}` }} className={styles.header}>
        <nav className={styles.desktopNav}>
          <div className={styles.desktopNav__left}>
            <Logo />
            <span className={styles.desktopNav__left__search}>
              <SearchBar />
            </span>
          </div>
          <div className={styles.desktopNav__mid}>
            <HeaderMenu />
          </div>
          <div className={styles.desktopNav__right}>
            <div
              onClick={openLocationModal}
              style={{ flexDirection: `${i18n.language === "ar" ? "row-reverse" : ""}` }}
              className={styles.desktopNav__right__location}>
              <FaLocationDot className={styles.desktopNav__right__location__icon} />
              <p className={styles.desktopNav__right__location__para}>{locationDet.city ? locationDet.city : "Location"}</p>
            </div>
            {!loggedIn ? (
              <div onClick={onLoginOpen} className={styles.desktopNav__right__login}>
                <p>{t("login")}</p>
              </div>
            ) : (
              <>
                <div onClick={openDashboardModal} className={styles.desktopNav__right__profile}>
                  <img src={profile ? profile : user} alt="profile" />
                </div>
                {openDashboard && <Dashboard />}
                {openProfile && <Profile onClose={onClose} />}
              </>
            )}
          </div>
          <div onClick={onOpenMenuBar} className={styles.desktopNav__menuBar}>
            <img src={menuBar} alt="err" />
          </div>
          {openMenu && <MobileNave onLoginOpen={onLoginOpen} profile={profile} />}
        </nav>
        <div className={styles.header__searchMobile}>
          <SearchBar width="100%" />
        </div>
      </header>
    </>
  );
};

export default DesktopNav;
