import React from "react";
import styles from "./index.module.scss";
import { FaChrome, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

const Bottom = () => {
  return (
    <div className={styles.bottom}>
      <ul className={styles.bottom__left}>
        <li className={styles.bottom__left__li}>
          <p className={styles.bottom__left__li__icon}>
            <FaPhoneAlt className={styles.bottom__left__li__icon__i} />
          </p>
          <span className={styles.bottom__left__li__span}>+91 7080581133</span>
        </li>
        <li className={styles.bottom__left__li}>
          <p className={styles.bottom__left__li__icon}>
            <FaEnvelope className={styles.bottom__left__li__icon__i} />
          </p>
          <span className={styles.bottom__left__li__span}>contact@experienceit.in</span>
        </li>
        <li className={styles.bottom__left__li}>
          <p className={styles.bottom__left__li__icon}>
            <FaChrome className={styles.bottom__left__li__icon__i} />
          </p>
          <span className={styles.bottom__left__li__span}>https://xperienceit.in/</span>
        </li>
      </ul>
      <h1>Thank You For Purchased</h1>
    </div>
  );
};

export default Bottom;
