import React from "react";
import logo from "../../../assets/logo.svg";
import styles from "./index.module.scss";

const Top = () => {
  return (
    <div className={styles.top}>
      <div className={styles.top__left}>
        <div className={styles.top__left__content}>
          <div className={styles.top__left__content__imgCont}>
            <img src={logo} alt="logo" />
          </div>
          <div className={styles.top__left__content__comp}>
            <h1>XPERIENCE IT</h1>
            <p>Come Xperience It With Us</p>
          </div>
        </div>
      </div>
      <div className={styles.top__right}>
        <div className={styles.top__right__content}>
          <h1>Invoice</h1>
          <p className={styles.top__right__content__det}>
            <span>Invoice No:</span>
            <span className={styles.top__right__content__det__val}>123456</span>
          </p>
          <p className={styles.top__right__content__det}>
            <span>Invoice Date:</span>
            <span className={styles.top__right__content__det__val}>Jan 10, 2024</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Top;
