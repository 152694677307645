import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "uiSlice",
  initialState: {
    isModalVisible: false,
    cityName: { city: "", id: null },
    isLoading: true,
    skelatonLoading: true,
    packagesLoading: true,
    openLoginModal: false,
    detailsLoading: true,
    isLoggedIn: false,
    openDashboard: false,
    showMenu: false,
    openProfile: false,
    isMarriage: false,
    isTour: false,
    isMegaMenu: false,
  },
  reducers: {
    openLocationModal: (state, action) => {
      state.isModalVisible = action.payload.isTrue;
    },
    chooseCity: (state, action) => {
      state.cityName = {
        city: action.payload.city,
        id: action.payload.id,
      };
    },
    currentlyLoading: (state, action) => {
      state.isLoading = action.payload.loading;
    },
    skelatonLoadingCurrently: (state, action) => {
      state.skelatonLoading = action.payload.loading;
    },
    isPackagesLoading: (state, action) => {
      state.packagesLoading = action.payload.loading;
    },
    isLoginModalOpen: (state, action) => {
      state.openLoginModal = action.payload.open;
    },
    isDetailsLoad: (state, action) => {
      state.detailsLoading = action.payload.loading;
    },
    userIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload.loggedIn;
      localStorage.setItem("isLoggedIn", JSON.stringify(state.isLoggedIn));
      localStorage.removeItem("otp");
      localStorage.removeItem("phoneNo");
    },
    userIsLogout: (state, action) => {
      state.isLoggedIn = action.payload.loggedIn;
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("access_token");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("user");
      state.openDashboard = action.payload.isClosed;
    },
    isDashboardOpen: (state, action) => {
      state.openDashboard = action.payload.isOpen;
    },

    showMenu: (state, action) => {
      state.showMenu = action.payload.isShoMenu;
    },
    isProfileOpen: (state, action) => {
      state.openProfile = action.payload.open;
    },
    isMarriagePopupOpen: (state, action) => {
      state.isMarriage = action.payload.isTrue;
    },
    isTourFormOpen: (state, action) => {
      state.isTour = action.payload.isTrue;
    },
    onOpenMegaMenu: (state, action) => {
      state.isMegaMenu = action.payload.isOpen;
    },
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice.reducer;
