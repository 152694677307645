import React, { useState } from "react";
import styles from "./index.module.scss";
import { IoMdClose } from "react-icons/io";
import Modal from "../index";
import CustomizeCard from "../../components/CustomizeCard";
import Button from "../../widgets/Button";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

const Customization = (props) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState({});
  const [totalPrice, setTotalPrice] = useState(props.total);

  const onAddCustomize = (e, all) => {
    let sum = totalPrice;
    let checked = e.target.checked;
    props.setSelectedCustomization((prev) => ({ ...prev, [all.id]: { ...all, selected: checked } }));
    if (checked) {
      sum += all.price;
    } else {
      sum -= all.price;
    }

    setTotalPrice(sum);
  };

  const onIncrement = (item) => {
    let quantity = counter[item.id] || 0;
    setCounter((prev) => ({ ...prev, [item.id]: quantity + 1 }));
    props.setSelectedCustomization((prev) => ({ ...prev, [item.id]: { ...item, userCount: quantity + 1 } }));
    setTotalPrice((prev) => prev + item.price);
  };

  const onDecrement = (item) => {
    let quantity = counter[item.id] || 0;
    setCounter((prev) => ({ ...prev, [item.id]: quantity - 1 }));
    props.setSelectedCustomization((prev) => ({ ...prev, [item.id]: { ...item, userCount: quantity - 1 } }));
    setTotalPrice((prev) => prev - item.price);
  };

  return (
    <Modal>
      <div className={styles.customization}>
        <div className={styles.customization__top}>
          <span onClick={props.onClose} className={styles.customization__top__close}>
            <IoMdClose />
          </span>
        </div>
        {props.loading ? (
          <div className={styles.customization__loader}>
            <Loader />
          </div>
        ) : (
          <div className={styles.customization__mid}>
            <div className={styles.customization__mid__content}>
              {props?.data?.map((item) => {
                return (
                  <CustomizeCard
                    key={item.id}
                    all={item}
                    img={item.image}
                    title={item.title}
                    desc={item.description}
                    price={item.price}
                    quantity={item.quantity}
                    counter={counter[item.id] || 0}
                    onAddCustomize={onAddCustomize}
                    onDecrement={onDecrement}
                    onIncrement={onIncrement}
                  />
                );
              })}
            </div>
          </div>
        )}
        <div className={styles.customization__bottom}>
          <div className={styles.customization__bottom__left}>
            <h3>{t("details.total")}</h3>
            <p>Rs {totalPrice}</p>
          </div>
          <div className={styles.customization__bottom__right}>
            <div className={styles.customization__bottom__right__btn}>
              <Button name={t("details.book")} margin="0" onClick={props.onHandleBook} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Customization;
