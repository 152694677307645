import React, { forwardRef } from "react";
import Title from "../Title";
import styles from "./index.module.scss";

const Arrangements = forwardRef((props, ref) => {
  return (
    <div ref={ref} className={styles.arrangements}>
      <Title title={props.title} />
      <div className={styles.arrangements__content} dangerouslySetInnerHTML={{ __html: props.arrangements }}></div>
    </div>
  );
});

export default Arrangements;
