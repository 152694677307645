import React, { useEffect, useState } from "react";
import prodImg from "../../assets/product.svg";
import smallImg from "../../assets/thumbnail.svg";
import styles from "./index.module.scss";
import SmallViews from "./SmallViews";
import { FaStar } from "react-icons/fa";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

const ProductView = (props) => {
  const [galleryImg, setGalleryImg] = useState("");
  const [itemIndex, setItemIndex] = useState(0);
  const detailsLoad = useSelector((state) => state.ui.detailsLoading);

  const getGalleryImage = (img, ind) => {
    setGalleryImg(img);
    setItemIndex(ind);
  };

  useEffect(() => {
    let starting = props.gallery && props?.gallery[0];
    setGalleryImg(starting);
  }, [props?.gallery]);

  return (
    <div className={styles.productView}>
      {detailsLoad ? (
        <Skeleton variant="rectangular" height={550} style={{ marginTop: 0 }} className={styles.productView__bigView} />
      ) : (
        <div className={styles.productView__bigView}>
          <img src={galleryImg !== "https://admin.xperienceit.in/uploads" ? galleryImg : prodImg} alt="main_bg" />
          <div className={styles.productView__bigView__rating}>
            <FaStar className={styles.productView__bigView__rating__icon} />
            <p>{props.all.rating}</p>
          </div>
        </div>
      )}
      <div className={styles.productView__left}>
        {detailsLoad ? (
          <Skeleton
            variant="rectangular"
            height={80}
            style={{ backgroundColor: "gray", zIndex: 10 }}
            className={styles.smallViews}
          />
        ) : (
          <>
            {props?.gallery?.map((item, index) => {
              return (
                <SmallViews
                  key={index}
                  src={item !== "https://admin.xperienceit.in/uploads" ? item : smallImg}
                  onClick={() => getGalleryImage(item, index)}
                  className={itemIndex === index ? styles.active : ""}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductView;
