import React from "react";
import styles from "./index.module.scss";
import notFound from "../../assets/animation/pagenotfound.json";
import Lottie from "lottie-react";
import ButtonTwo from "../../widgets/ButtonTwo";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const backToHome = () => {
    navigate("/");
  };
  return (
    <div className={styles.notFound}>
      <div className={styles.notFound__animCont}>
        <Lottie loop={true} animationData={notFound} className={styles.notFound__animCont__animation} />
      </div>
      <div className={styles.notFound__btnCont}>
        <ButtonTwo onClick={backToHome} name="Back To Home" />
      </div>
    </div>
  );
};

export default NotFound;
