import React from "react";
import styles from "./index.module.scss";
import ios from "../../../assets/ios.svg";
import android from "../../../assets/playstore.svg";
import Title from "../Title";
import { useTranslation } from "react-i18next";

const ForStore = () => {
  const { t, i18n } = useTranslation();
  return (
    <div style={{ textAlign: `${i18n.language === "ar" ? "right" : "left"}` }} className={styles.forStore}>
      <Title title={t("download")} />
      <div className={styles.forStore__store}>
        <div className={styles.forStore__store__img}>
          <img src={android} alt="android" />
        </div>
        <div className={styles.forStore__store__img}>
          <img src={ios} alt="ios" />
        </div>
      </div>
    </div>
  );
};

export default ForStore;
