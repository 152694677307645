import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const SeeAll = ({ onClick }) => {
  const { i18n } = useTranslation();
  return (
    <p onClick={onClick} style={{ fontWeight: `${i18n.language === "ar" ? "400" : "400"}` }} className={styles.button}>
      {i18n.language === "ar" ? "عرض الكل" : "See All"}
    </p>
  );
};

export default SeeAll;
