import React from "react";
import Title from "../Title";
import { FaEnvelope, FaPhone, FaWhatsapp } from "react-icons/fa6";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const ForServices = () => {
  const { t, i18n } = useTranslation();
  return (
    <div style={{ textAlign: `${i18n.language === "ar" ? "right" : "left"}` }} className={styles.forServices}>
      <Title title={t("callNow")} />
      <ul className={styles.forServices__ul}>
        <li className={styles.forServices__ul__li}>
          <span className={styles.forServices__ul__li__icon}>
            <FaPhone className={styles.forServices__ul__li__icon__i} fontSize="20px" />
          </span>
          <span className={styles.forServices__ul__li__para}>{t("tel")}</span>
        </li>
        <li className={styles.forServices__ul__li}>
          <span className={styles.forServices__ul__li__icon}>
            <FaEnvelope className={styles.forServices__ul__li__icon__i} fontSize="20px" />
          </span>
          <span className={styles.forServices__ul__li__para}>contact@experienceit.in</span>
        </li>
        <li className={styles.forServices__ul__li}>
          <span className={styles.forServices__ul__li__icon}>
            <FaWhatsapp className={styles.forServices__ul__li__icon__i} fontSize="20px" />
          </span>
          <span className={styles.forServices__ul__li__para}>{t("tel")}</span>
        </li>
      </ul>
    </div>
  );
};

export default ForServices;
