import React from "react";
import Title from "../Title";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom/dist";

const ContactUs = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onNavigateToContact = () => {
    navigate("/contact-us");
  };

  const onNavigateToWhoWeAre = () => {
    navigate("/who-we-are");
  };

  const onNavigateToBlog = () => {
    navigate("/blogs");
  };

  return (
    <div style={{ textAlign: `${i18n.language === "ar" ? "right" : "left"}` }} className={styles.contactUs}>
      <Title title={t("contact")} />
      <ul className={styles.contactUs__ul}>
        <li onClick={onNavigateToContact} style={{ marginTop: "0" }} className={styles.contactUs__ul__li}>
          {t("contact")}
        </li>
        <li className={styles.contactUs__ul__li}>
          <a href="https://skyviewads.com/" target="_blank" rel="noreferrer">
            {t("company")}
          </a>
        </li>
        <li onClick={onNavigateToWhoWeAre} className={styles.contactUs__ul__li}>
          {t("who")}
        </li>
        <li onClick={onNavigateToBlog} className={styles.contactUs__ul__li}>
          {t("blogs")}
        </li>
      </ul>
    </div>
  );
};

export default ContactUs;
