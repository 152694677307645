import React from "react";
import styles from "./index.module.scss";

const LocationCard = (props) => {
  return (
    <div className={styles.locationCard}>
      <div className={styles.locationCard__img}>
        <img src={props.img} alt={props.title} />
      </div>
      <p className={styles.locationCard__title}>{props.title}</p>
    </div>
  );
};

export default LocationCard;
