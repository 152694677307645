import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../widgets/Input";
import styles from "./index.module.scss";
import Container from "../../shared/Container";
import ButtonTwo from "../../widgets/ButtonTwo";
import contact1 from "../../assets/contact1.svg";
import contact2 from "../../assets/contact2.svg";
import contact3 from "../../assets/contact3.svg";

export default function Contact() {
  const { t } = useTranslation("translation");

  const [enquiryDetails, setEnquiryDetails] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
    city: "",
  });

  const onContactUs = (e, id) => {
    setEnquiryDetails((prev) => ({ ...prev, [id]: e.target.value }));
  };

  const onSubmitEnquiryForm = () => {
    console.log(enquiryDetails);
  };

  return (
    <Container>
      <div className={styles.contact}>
        <div className={styles.contact__header}>
          <h3 className={styles.contact__header__title}>{t("contacttitle1")}</h3>
          <h3 className={styles.contact__header__title}>{t("contacttitle2")}</h3>
          <h3 className={styles.contact__header__title}>
            {t("contacttitle3")}
            <a href="mailto:contact@experienceit.in" className="btn btn-text hovered">
              contact@experienceit.in
            </a>
          </h3>
        </div>

        <div className={styles.contact__info}>
          <div className={styles.contact__info__left}>
            <h3>{t("stay")}</h3>
            <Input
              id="subject"
              value={enquiryDetails.subject}
              onChange={(e) => onContactUs(e, "subject")}
              label={t("placeholder.subject")}
            />
            <div className={styles.contact__info__left__oneline}>
              <Input
                id="name"
                value={enquiryDetails.name}
                onChange={(e) => onContactUs(e, "name")}
                label={t("placeholder.name")}
              />
              <Input
                id="email"
                value={enquiryDetails.email}
                onChange={(e) => onContactUs(e, "email")}
                label={t("placeholder.email")}
              />
            </div>
            <div className={styles.contact__info__left__oneline}>
              <Input
                id="city"
                value={enquiryDetails.city}
                onChange={(e) => onContactUs(e, "city")}
                label={t("placeholder.city")}
              />
              <Input
                id="phone"
                value={enquiryDetails.phone}
                onChange={(e) => onContactUs(e, "phone")}
                label={t("placeholder.number")}
              />
            </div>
            <textarea
              id="message"
              value={enquiryDetails.message}
              onChange={(e) => onContactUs(e, "message")}
              className={styles.contact__info__left__textarea}
              placeholder={t("placeholder.message")}
            />
            <ButtonTwo name={t("placeholder.send")} onClick={onSubmitEnquiryForm} />
          </div>
          <div className={styles.contact__info__right}>
            <div className={styles.contact__info__right__top}>
              <div className={styles.contact__info__right__top__box}>
                <div className={styles.contact__info__right__top__box__icon}>
                  <img src={contact1} alt="contact1" />
                </div>
                <div className={styles.contact__info__right__top__box__content}>
                  <h1>{t("sup1")}</h1>
                  <a href="mailto:contact@experienceit.in" className="btn btn-text btn-text--black">
                    contact@experienceit.in
                  </a>
                </div>
              </div>

              <div className={styles.contact__info__right__top__box}>
                <div className={styles.contact__info__right__top__box__icon}>
                  <img src={contact2} alt="contact2" />
                </div>
                <div className={styles.contact__info__right__top__box__content}>
                  <h1>{t("sup2")}</h1>
                  <a href="mailto:contact@experienceit.in" className="btn btn-text btn-text--black">
                    contact@experienceit.in
                  </a>
                </div>
              </div>
              <div className={styles.contact__info__right__top__box}>
                <div className={styles.contact__info__right__top__box__icon}>
                  <img src={contact3} alt="contact3" />
                </div>
                <div className={styles.contact__info__right__top__box__content}>
                  <h1>{t("sup3")}</h1>
                  <a
                    href="https://api.whatsapp.com/send/?phone=917080581133&text&app_absent=0"
                    className="btn btn-text btn-text--black">
                    {t("tel")}
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.contact__info__right__bottom}>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d629.0275415402073!2d80.97235963006686!3d26.89342898410601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39995754fadda861%3A0xbddcc736f4743c8f!2sSkyview%20Smart%20Solutions-%20Best%20Website%20Designing%20%7C%20Software%20Development%20%7C%20Digital%20Marketing%20%7C%20SEO%20%7C%20IT%20Company%20in%20Lucknow!5e0!3m2!1sen!2sin!4v1702738335225!5m2!1sen!2sin"
                style={{ border: 0, width: "100%", height: "300px" }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
