import React from "react";
import styles from "./index.module.scss";

const ToFrom = () => {
  return (
    <div className={styles.toFrom}>
      <div className={styles.toFrom__left}>
        <h2>Bill To:</h2>
        <p>Md Ale Mustafa</p>
        <ul className={styles.toFrom__left__ul}>
          <li className={styles.toFrom__left__ul__li}>
            <span>Contact: </span>
            <span className={styles.toFrom__left__ul__li__span}>9471161786</span>
          </li>
          <li className={styles.toFrom__left__ul__li}>
            <span>Email: </span>
            <span className={styles.toFrom__left__ul__li__span}>mustafa.skyview@gmail.com</span>
          </li>
          <li className={styles.toFrom__left__ul__li}>
            <span>Address: </span>
            <span className={styles.toFrom__left__ul__li__span}>
              Kamla Nehru Nagar, Khurram Nagar, Lucknow, Uttar Pradesh, 226022
            </span>
          </li>
        </ul>
      </div>
      <div className={styles.toFrom__right}>
        <h2>Xperience It Events Pvt Ltd</h2>
        <ul className={styles.toFrom__right__ul}>
          <li className={styles.toFrom__right__ul__li}>
            <span className={styles.toFrom__right__ul__li__span}>
              529, Hans Bhakti Dham, 39, Kamla Nehru Nagar, Khurram Nagar, Lucknow, Uttar Pradesh 226022
            </span>
          </li>
          <li className={styles.toFrom__right__ul__li}>
            <span>Mobile: </span>
            <span className={styles.toFrom__right__ul__li__span}>+91 7080581133</span>
          </li>
          <li className={styles.toFrom__right__ul__li}>
            <span>Website: </span>
            <span className={styles.toFrom__right__ul__li__span}>https://xperienceit.in/</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ToFrom;
