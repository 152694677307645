import React from "react";
import styles from "./index.module.scss";
import Title from "../Title";
import { useTranslation } from "react-i18next";

const KnowAbout = ({ onKnowAbout, selectedPlatform }) => {
  const { t, i18n } = useTranslation();
  const knowAbout = [
    { id: 1, nameEn: "Advertisement", nameAr: "إعلان" },
    { id: 2, nameEn: "Friend", nameAr: "صديق" },
    { id: 3, nameEn: "Google", nameAr: "جوجل" },
    { id: 4, nameEn: "Instagram", nameAr: "انستغرام" },
    { id: 5, nameEn: "Facebook", nameAr: "فيسبوك" },
    { id: 6, nameEn: "Email", nameAr: "بريد إلكتروني" },
    { id: 7, nameEn: "Used Before", nameAr: "تستخدم من قبل" },
    { id: 8, nameEn: "Amazon", nameAr: "أمازون" },
    { id: 9, nameEn: "Other", nameAr: "خيار آخر" },
  ];

  return (
    <div className={styles.knowAbout}>
      <Title title={t("checkout.knowAbout")} />
      <ul className={styles.knowAbout__ul}>
        {knowAbout.map((item) => {
          return (
            <li
              style={{
                backgroundColor: item.nameEn === selectedPlatform ? "#ffab02" : "",
                color: item.nameEn === selectedPlatform ? "#fff" : "",
                border: item.nameEn === selectedPlatform ? "1px solid #ffab02" : "",
              }}
              onClick={() => onKnowAbout(item)}
              className={styles.knowAbout__ul__li}
              key={item.id}>
              <p>{i18n.language === "ar" ? item.nameAr : item.nameEn}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default KnowAbout;
