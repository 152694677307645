import React from "react";
import styles from "./index.module.scss";

const ToggleSwitch = ({ id, onChange }) => {
  return (
    <div className={styles.toggler}>
      <input onChange={onChange} id={id} name="toggler-1" type="checkbox" value="1" />
      <label htmlFor={id}></label>
    </div>
  );
};

export default ToggleSwitch;
