import React from "react";
import Title from "../Title";
import styles from "./index.module.scss";

const TermAndCondition = ({ tAndC, title }) => {
  return (
    <div className={styles.tAc}>
      <Title title={title} />
      <div className={styles.tAc__content} dangerouslySetInnerHTML={{ __html: tAndC }}></div>
    </div>
  );
};

export default TermAndCondition;
