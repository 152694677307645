import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { getWhoWeAre } from "../../services/server";
import Container from "../../shared/Container";
import { Skeleton } from "@mui/material";

const WhoWeAre = () => {
  const [loading, setLoading] = useState(true);
  const [about, setAbout] = useState([]);
  const getData = async () => {
    setLoading(true);
    const res = await getWhoWeAre();
    if (res.status) {
      setAbout(res.body);
      setLoading(false);
    } else {
      setAbout([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <div className={styles.whoWeAre}>
        {loading ? (
          <Skeleton variant="rectangular" height={300} />
        ) : (
          <>
            <h2>{about[0].title}</h2>
            <p dangerouslySetInnerHTML={{ __html: about[0].desc }}></p>
          </>
        )}
      </div>
    </Container>
  );
};

export default WhoWeAre;
