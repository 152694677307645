import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Title from "../Title";
import { FaCalendar, FaClock } from "react-icons/fa";
import ButtonTwo from "../../../widgets/ButtonTwo";
import Customization from "../../../modals/Customization";
import thumbnail from "../../../assets/thumbnail.svg";
import { useTranslation } from "react-i18next";

const OrderSummary = ({ data, onCheckout, custome, loading }) => {
  const [show, setShow] = useState(false);
  const [sum, setSum] = useState(0);
  const { t } = useTranslation();
  const checkoutRef = useRef();

  let gstPrice = +data.gstPrice.split(",").join("");
  const customizeData = Object.values(custome);

  useEffect(() => {
    let totalSum = 0;

    customizeData.forEach((item) => {
      totalSum += item.singlepiece ? item.price : item.userCount * item.price;
    });

    setSum(totalSum);
  }, [customizeData]);

  let totalPrice = gstPrice + data.deliveryCharge + sum;

  const openCustomization = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };

  const onViewCheckout = () => {
    checkoutRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {show && (
        <Customization
          onClose={onClose}
          onHandleBook={openCustomization}
          total={totalPrice}
          data={customizeData}
          screen="checkout"
          // setSelectedCustomization={setSelectedCustomization}
        />
      )}
      <div ref={checkoutRef} className={styles.order}>
        <Title title={t("checkout.orderS")} />
        <div className={styles.order__top}>
          <div className={styles.order__top__left}>
            <FaCalendar className={styles.order__top__left__icon} />
            <p>{data.booking_date}</p>
          </div>
          <div className={styles.order__top__right}>
            <FaClock className={styles.order__top__right__icon} />
            <p>{data.timeslot}</p>
          </div>
        </div>
        <div className={styles.order__mid}>
          <div className={styles.order__mid__left}>
            <div className={styles.order__mid__left__img}>
              <img src={!data?.img?.includes("https://admin.xperienceit.in/uploads") ? data.img : thumbnail} alt="product" />
            </div>
            <div className={styles.order__mid__left__det}>
              <h3>{data.productTitle}</h3>
              <p>(Inc. {data.gstPercent}% GST)</p>
            </div>
          </div>

          <p className={styles.order__mid__right}>Rs {gstPrice}</p>
        </div>
        <div className={styles.order__delivery}>
          <p>{t("checkout.dCharges")}</p>
          <p>Rs {data.deliveryCharge}</p>
        </div>
        <div className={styles.order__custom}>
          {/* <p className={styles.order__custom__p} onClick={openCustomization}> */}
          <p className={styles.order__custom__p}>
            {t("checkout.custom")}
            {/* <FaEdit className={styles.order__custom__p__icon} /> */}
          </p>
          {customizeData.length < 1 && <p className={styles.order__custom__noData}> {t("checkout.noCustom")}</p>}
          {customizeData.length > 0 && (
            <table className={styles.order__custom__table}>
              <tbody>
                <tr>
                  <th> {t("checkout.title")}</th>
                  <th> {t("checkout.quantity")}</th>
                  <th> {t("checkout.price")}</th>
                </tr>
                {customizeData.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.title}</td>
                      <td>{item.singlepiece ? item.quantity : item.userCount}</td>
                      <td>{item.singlepiece ? item.price : item.userCount * item.price}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <div className={styles.order__delivery}>
          <p>{t("details.total")}</p>
          <p>Rs {totalPrice}</p>
        </div>
        <ButtonTwo name={t("details.checkout")} onClick={() => onCheckout(totalPrice)} loading={loading} />
        <div className={styles.order__btn}>
          <ButtonTwo name={t("details.checkout")} onClick={onViewCheckout} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default OrderSummary;
