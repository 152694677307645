import React from "react";
import styles from "./index.module.scss";
import i18n from "../../i18n";

const Title = (props) => {
  return (
    <div className={styles.title}>
      {props.title && (
        <h1
          style={{
            color: `${props.color1}`,
            textAlign: `${props.textAlign}`,
            // fontFamily: `${i18n.language === "ar" ? "SF-Regular" : "Great Vibes"}`,
          }}>
          {props.title}
        </h1>
      )}
      <h3 style={{ color: `${props.color}`, fontFamily: `${i18n.language === "ar" ? "SF-Bold" : "Poppins"}` }}>
        {props.headline}
      </h3>
    </div>
  );
};

export default Title;
