import React, { useEffect, useState } from "react";
import Categories from "../../components/Categories";
import Container from "../../shared/Container";
import styles from "./index.module.scss";
import favourite from "../../assets/offers/favourite.png";
import { getFavouritesList } from "../../services/server";
import { Skeleton } from "@mui/material";
import Card from "../../components/Card";
import { uiActions } from "../../utils/reducers/ui";
import { useDispatch, useSelector } from "react-redux";
import { productsActions } from "../../utils/reducers/product";
import { useTranslation } from "react-i18next";

const Favourites = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const isFav = useSelector((state) => state.products.isFavrtExist);

  const [loading, setLoading] = useState(false);
  const getFavourites = async () => {
    setLoading(true);
    dispatch(
      uiActions.skelatonLoadingCurrently({
        loading: true,
      })
    );
    const res = await getFavouritesList();
    if (res?.status) {
      setData(res.body);
      setLoading(false);
      dispatch(
        productsActions.allWishList({
          favrtList: res.body,
        })
      );

      dispatch(
        uiActions.skelatonLoadingCurrently({
          loading: false,
        })
      );
    } else {
      setData([]);
      setLoading(false);
      dispatch(
        uiActions.skelatonLoadingCurrently({
          loading: false,
        })
      );
    }
  };

  useEffect(() => {
    getFavourites();
    // eslint-disable-next-line
  }, [isFav]);

  return (
    <>
      <Categories />
      <Container>
        <div className={styles.wishlist}>
          <h1>{t("booking.fav")}</h1>
          {loading ? (
            <div className={styles.wishlist__body}>
              {[1, 2, 3, 4].map((item) => {
                return <Skeleton key={item} variant="rectangular" height={350} style={{ borderRadius: "10px" }} />;
              })}
            </div>
          ) : (
            <>
              {data.length === 0 ? (
                <img src={favourite} alt="favourite" />
              ) : (
                <div className={styles.wishlist__body}>
                  {data.map((item) => {
                    return (
                      <Card
                        id={item.id}
                        key={item.id}
                        title={item.title}
                        src={item.image}
                        price={item.discounted_price}
                        original_price={item.price}
                        discount={item.discount_percent}
                        masterId={item.category}
                        subId={item.subcat_id}
                        data={item}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default Favourites;
