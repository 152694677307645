import React from "react";
import { Navigate } from "react-router-dom";
import Public from "../Public";
import { uiActions } from "../../utils/reducers/ui";
import { useDispatch } from "react-redux";

const Private = ({ element, ...rest }) => {
  const dispatch = useDispatch();
  const access_token = localStorage.getItem("access_token");

  if (!access_token) {
    dispatch(
      uiActions.isLoginModalOpen({
        open: true,
      })
    );
  }

  return access_token ? <Public element={element} /> : <Navigate to="/" replace />;
};

export default Private;
