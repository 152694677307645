import React, { forwardRef } from "react";
import styles from "./index.module.scss";
import ReactPlayer from "react-player";
import Title from "../Title";

const Videos = forwardRef((props, ref) => {
  return (
    <div ref={ref} className={styles.videos}>
      <Title title={props.title} />
      <ReactPlayer className={styles.videos__content} url={props.video} />
    </div>
  );
});

export default Videos;
