import React from "react";
import styles from "./index.module.scss";
import List from "./Item";
import { HiHome, HiMiniGift } from "react-icons/hi2";
// import { MdGTranslate } from "react-icons/md";
import { GiBigDiamondRing } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa6";
import MegaMenu from "../MegaMenu";
import { useLocation } from "react-router-dom";
import Marriage from "../../modals/Marriage";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../utils/reducers/ui";
// import { endpoints } from "../../services/endpoints";
// import axios from "axios";

const HeaderMenu = () => {
  // const [categoryList, setCategoryList] = useState([]);
  const { t, i18n } = useTranslation("translation");
  const location = useLocation();
  const dispatch = useDispatch();
  const isMarriage = useSelector((state) => state.ui.isMarriage);
  const isMegaMenu = useSelector((state) => state.ui.isMegaMenu);
  // const langDet = JSON.parse(localStorage.getItem("langDet"));

  // const onlanguagechange = (l, direction) => {
  //   const langDet = {
  //     l: l,
  //     direction: direction,
  //   };
  //   i18n.changeLanguage(l, () => {
  //     i18n.dir(direction);
  //   });
  //   document.body.dir = i18n.dir();
  //   localStorage.setItem("langDet", JSON.stringify(langDet));
  // };

  // useEffect(() => {
  //   if (langDet) {
  //     i18n.changeLanguage(langDet.l, () => {
  //       i18n.dir(langDet.direction);
  //     });
  //     document.body.dir = langDet.direction;
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const openMarriageModal = () => {
    dispatch(
      uiActions.isMarriagePopupOpen({
        isTrue: true,
      })
    );
  };

  const onOpenMegaMenu = () => {
    dispatch(
      uiActions.onOpenMegaMenu({
        isOpen: true,
      })
    );
  };

  const onCloseMegaMenu = (e) => {
    e.stopPropagation();
    dispatch(
      uiActions.onOpenMegaMenu({
        isOpen: false,
      })
    );
  };

  return (
    <>
      {isMarriage && <Marriage />}
      <ul className={styles.headerMenu}>
        <li onClick={onOpenMegaMenu} className={styles.headerMenu__pick}>
          <span>{t("pickXperience")}</span>
          {isMegaMenu && (
            <>
              <div onClick={onCloseMegaMenu} className={styles.overlay}></div>
              <div
                style={{
                  left: `${i18n.language === "ar" ? "" : "50%"}`,
                  right: `${i18n.language === "ar" ? "50%" : ""}`,
                  transform: `translate(${i18n.language === "ar" ? "50%" : "-50%"})`,
                }}
                className={styles.headerMenu__pick__services}>
                <MegaMenu />
              </div>
            </>
          )}
          <span className={` ${i18n.language === "ar" ? styles.headerMenu__pick__iconHover : styles.headerMenu__pick__icon}`}>
            <FaAngleDown />
          </span>
        </li>

        <List path="/" color={location.pathname === "/" ? "#ffab02" : ""} icon={<HiHome />} name={t("home")} />
        <List path="/gifts" color={location.pathname === "/gifts" ? "#ffab02" : ""} icon={<HiMiniGift />} name={t("gifts")} />
        <List onClick={openMarriageModal} icon={<GiBigDiamondRing />} name={t("marriage")} />
      </ul>
    </>
  );
};

export default HeaderMenu;
