import React, { useEffect, useState } from "react";
import { endpoints } from "../../services/endpoints";
import axios from "axios";
import Container from "../../shared/Container";
import { Skeleton } from "@mui/material";
import styles from "./index.module.scss";

const Terms = () => {
  const [loading, setLoading] = useState(true);
  const [terms, setTerms] = useState([]);
  useEffect(() => {
    const url = endpoints.policies.termscondition;
    setLoading(true);
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setTerms(res.data.body);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Container>
      <div className={styles.terms}>
        {loading ? (
          <Skeleton variant="rectangular" height={300} />
        ) : (
          <>
            <h2>{terms[0]?.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: terms[0]?.desc }}></p>
          </>
        )}
      </div>
    </Container>
  );
};

export default Terms;
