import React, { useEffect, useState } from "react";
import { endpoints } from "../../services/endpoints";
import styles from "./index.module.scss";
import axios from "axios";
import Carousel from "react-multi-carousel";
import homeBanner from "../../assets/homeBanner.svg";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";

const HomeBanner = () => {
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const [bannerImg, setBannerImg] = useState([]);

  const getBanner = () => {
    const bannerUrl = endpoints.home.banner;
    const headers = {
      Accept: "application/json",
    };

    axios
      .get(bannerUrl, { headers: headers })
      .then((res) => {
        if (res.data.status === true) {
          const val = res.data.body;
          setBannerImg(val);
          setLoading(false);
        } else if (res.data.status === false) {
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getBanner();
    // eslint-disable-next-line
  }, [i18n.language]);

  const rtl = i18n.language === "ar" ? true : false;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 480 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {loading ? (
        <div className={styles.banner}>
          <Skeleton variant="rectangular" height={445} />
        </div>
      ) : (
        <div className={styles.banner}>
          <Carousel
            rtl={rtl}
            arrows={false}
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            customTransition="all .5s"
            transitionDuration={500}
            autoPlaySpeed={2000}
            showDots={true}
            dotListClass="custom-dot-list-style"
            infinite={true}
            partialVisible={false}>
            {bannerImg.map((banner, index) => {
              return (
                <div className={styles.banner__bgImg} key={index}>
                  <img src={banner.bg_images || homeBanner} alt="Home Banner" />
                </div>
              );
            })}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default HomeBanner;
