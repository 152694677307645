import place1 from "../assets/tours/albaladakaoldjeddah.webp";
import place2 from "../assets/tours/aldiriyahheritagevillage.webp";
import place3 from "../assets/tours/alrahmanmosquethefloatingmosque.webp";
import place4 from "../assets/tours/altayebatinternationalcitymuseum.webp";
import place5 from "../assets/tours/alulaheritagevillage.webp";
import place6 from "../assets/tours/cubamasjidinmadinah.webp";
import place7 from "../assets/tours/dheeaynmarblevillage.webp";
import place8 from "../assets/tours/edgeoftheworld.webp";
import place9 from "../assets/tours/elephantrockinalula.webp";
import place10 from "../assets/tours/hegraakamadainsaleh.webp";
import place11 from "../assets/tours/mountuhudinmadinah.webp";
import place12 from "../assets/tours/rijalalmaheritagevillage.webp";
import place13 from "../assets/tours/ushaiqerheritagevillage.webp";
import place14 from "../assets/tours/wadialdisanintabuk.webp";

export const vendors = [
  { id: 1, nameEn: "Venues", nameAr: "" },
  { id: 2, nameEn: "Photography and video", nameAr: "" },
  { id: 3, nameEn: "Wedding planners", nameAr: "" },
  { id: 4, nameEn: "Bridal Accessories", nameAr: "" },
  { id: 5, nameEn: "Transportation", nameAr: "" },
  { id: 6, nameEn: "Groom's Accessories", nameAr: "" },
  { id: 7, nameEn: "Entertainment", nameAr: "" },
  { id: 8, nameEn: "Ceremony", nameAr: "" },
  { id: 9, nameEn: "Catering", nameAr: "" },
  { id: 10, nameEn: "Jewellery", nameAr: "" },
  { id: 11, nameEn: "Wedding cards", nameAr: "" },
  { id: 12, nameEn: "Honeymoon", nameAr: "" },
  { id: 13, nameEn: "Flowers and Decoration", nameAr: "" },
  { id: 14, nameEn: "Health and Beauty", nameAr: "" },
  { id: 15, nameEn: "Wedding gifts", nameAr: "" },
  { id: 16, nameEn: "Mehendi Artist", nameAr: "" },
  { id: 17, nameEn: "Cakes", nameAr: "" },
  { id: 18, nameEn: "Other", nameAr: "" },
];

export const tours = [
  { id: 1, name: "Heritage Village", title: "Al Diriyah", image_path: place1, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
  { id: 2, name: "Al-Balad Aka Old Jeddah", title: "Jeddah", image_path: place2, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
  {
    id: 3,
    name: "The Floating Mosque",
    title: "Al-Rahman Mosque",
    image_path: place3,
    rtlName: "قرية التراث",
    rtlTitle: "الدرعية",
  },
  {
    id: 4,
    name: "City Museum",
    title: "Al-Tayebat International",
    image_path: place4,
    rtlName: "قرية التراث",
    rtlTitle: "الدرعية",
  },
  { id: 5, name: "Heritage Village", title: "Al Diriyah", image_path: place5, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
  { id: 6, name: "Heritage Village", title: "Al Diriyah", image_path: place6, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
  { id: 7, name: "Heritage Village", title: "Al Diriyah", image_path: place7, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
  { id: 8, name: "Heritage Village", title: "Al Diriyah", image_path: place8, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
  { id: 9, name: "Heritage Village", title: "Al Diriyah", image_path: place9, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
  { id: 10, name: "Heritage Village", title: "Al Diriyah", image_path: place10, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
  { id: 11, name: "Heritage Village", title: "Al Diriyah", image_path: place11, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
  { id: 12, name: "Heritage Village", title: "Al Diriyah", image_path: place12, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
  { id: 13, name: "Heritage Village", title: "Al Diriyah", image_path: place13, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
  { id: 14, name: "Heritage Village", title: "Al Diriyah", image_path: place14, rtlName: "قرية التراث", rtlTitle: "الدرعية" },
];

export const options = [
  { id: 1, nameEn: "Choose", nameAr: "اختار" },
  { id: 1, nameEn: "Bride", nameAr: "عروس" },
  { id: 1, nameEn: "Groom", nameAr: "عريس" },
  { id: 1, nameEn: "Relative", nameAr: "نسبي" },
  { id: 1, nameEn: "Guest", nameAr: "ضيف" },
];
