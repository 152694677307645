import React from "react";
import styles from "./index.module.scss";
import ProgressLoader from "../../shared/ProgressLoader";

const ButtonTwo = (props) => {
  return (
    <button style={{ margin: `${props.margin}` }} onClick={props.onClick} className={styles.btn}>
      {props.loading ? props.loading ? <ProgressLoader /> : props.name : props.name}
    </button>
  );
};

export default ButtonTwo;
