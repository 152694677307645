import React from "react";
import styles from "./index.module.scss";

const PayMethod = () => {
  return (
    <div className={styles.toFrom}>
      <div className={styles.toFrom__left}>
        <h2>Payment Method:</h2>
        <ul className={styles.toFrom__left__ul}>
          <li className={styles.toFrom__left__ul__li}>
            <span>Payment Date: </span>
            <span className={styles.toFrom__left__ul__li__span}>Jan 10, 2024</span>
          </li>
          <li className={styles.toFrom__left__ul__li}>
            <span>Amount Paid: </span>
            <span className={styles.toFrom__left__ul__li__span}>₹ 2276.00</span>
          </li>
          {/* <li className={styles.toFrom__left__ul__li}>
            <span>Address: </span>
            <span className={styles.toFrom__left__ul__li__span}>
              Kamla Nehru Nagar, Khurram Nagar, Lucknow, Uttar Pradesh, 226022
            </span>
          </li> */}
        </ul>
      </div>
      <div className={styles.toFrom__right}>
        <h2>Xperience: Black And Golden Birthday Decor</h2>
        <ul className={styles.toFrom__right__ul}>
          <li className={styles.toFrom__right__ul__li}>
            <span>Date: </span>
            <span className={styles.toFrom__right__ul__li__span}>Jan 10, 2024</span>
          </li>
          <li className={styles.toFrom__right__ul__li}>
            <span>Time: </span>
            <span className={styles.toFrom__right__ul__li__span}>10:00 AM - 08:00 PM</span>
          </li>
          <li className={styles.toFrom__right__ul__li}>
            <span>Venue: </span>
            <span className={styles.toFrom__right__ul__li__span}>
              Kamla Nehru Nagar, Khurram Nagar, Lucknow, Uttar Pradesh, 226022
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PayMethod;
