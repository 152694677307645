import React from "react";
import styles from "./index.module.scss";

const Card = ({ src, italic, bold, onTourForm }) => {
  return (
    <div onClick={onTourForm} className={styles.card}>
      <div className={styles.card__imgCont}>
        <img className={styles.card__imgCont__img} src={src} alt={bold} />
      </div>
      <div className={styles.card__content}>
        <p className={styles.card__content__italic}>{italic}</p>
        <h2 className={styles.card__content__bold}>{bold}</h2>
      </div>
    </div>
  );
};

export default Card;
