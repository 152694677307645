import React from "react";
import styles from "./index.module.scss";

const Container = ({ children }) => {
  return (
    <div className={styles.parent}>
      <div className={styles.parent__children}>{children}</div>
    </div>
  );
};

export default Container;
