import React from "react";
import Lottie from "lottie-react";
import coming from "../../assets/animation/coming.json";
import { useTranslation } from "react-i18next";

const Gifts = () => {
  const { i18n } = useTranslation();
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "50px 0" }}>
      <Lottie
        loop={true}
        animationData={coming}
        style={{ right: `${i18n.language === "ar" ? "" : "0"}`, left: `${i18n.language === "ar" ? "0" : ""}`, width: "70%" }}
      />
    </div>
  );
};

export default Gifts;
