import React from "react";
import Modal from "..";
import Button from "../../widgets/Button";
import styles from "./index.module.scss";
import Close from "../../components/Close";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

const Details = ({ items, closeDetailModal, loading, openCancelReason }) => {
  const { t } = useTranslation();
  return (
    <Modal>
      <div className={styles.details}>
        <h1>{t("cancel.bookDet")}</h1>
        <Close onClick={closeDetailModal} />
        {loading ? (
          <div className={styles.details__loader}>
            <Loader />
          </div>
        ) : (
          <>
            <p>
              <span>
                {t("cancel.date")} {items.dateMonth}
              </span>
              <span>
                {t("cancel.time")} {items.time}
              </span>
            </p>
            <p>
              <span>{items.title}</span>
              <span>₹{items.discounted_price}.00</span>
            </p>
            <p>
              <span>{t("cancel.addC")}</span>
              <span>₹{items.additional_charge}.00</span>
            </p>
            <p>
              <span style={{ fontWeight: "bold", letterSpacing: "1px" }}>{t("cancel.tCost")}</span>
              <span style={{ fontWeight: "bold", letterSpacing: "1px" }}>
                ₹{+items.additional_charge + +items.discounted_price}.00
              </span>
            </p>
            <Button onClick={openCancelReason} name={t("cancel.cBook")} />
          </>
        )}
      </div>
    </Modal>
  );
};

export default Details;
