import React, { useEffect, useState } from "react";
import Card from "../../components/Card";
import { useParams } from "react-router-dom";
import styles from "./index.module.scss";
import { uiActions } from "../../utils/reducers/ui";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../shared/Container";
import { endpoints } from "../../services/endpoints";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Angle from "../../widgets/Angle";

const AllProductList = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui.skelatonLoading);
  const isFav = useSelector((state) => state.products.isFavrtExist);

  const [allProductList, setAllProductList] = useState([]);
  useEffect(() => {
    const getPackageByChildCat = async () => {
      dispatch(
        uiActions.skelatonLoadingCurrently({
          loading: true,
        })
      );
      let url = endpoints.deatils.getPackageByChildCategory + atob(params.childId);
      let response = await fetch(url);
      let data = await response.json();
      setAllProductList(data.body);
      if (data.status === false) {
        dispatch(
          uiActions.skelatonLoadingCurrently({
            loading: false,
          })
        );
        throw new Error("Something Went Wrong!");
      }
      dispatch(
        uiActions.skelatonLoadingCurrently({
          loading: false,
        })
      );
    };
    getPackageByChildCat();
    // eslint-disable-next-line
  }, [params.childId, isFav]);

  return (
    <Container>
      <div className={styles.allProductList}>
        {params.masterCat ? (
          <h1 className={styles.allProductList__title}>
            {t("home")} <Angle />
            {params.masterCat.replaceAll("-", " ")} <Angle />
            {params.subCat.replaceAll("-", " ")}
            <Angle />
            {params.childCat.replaceAll("-", " ")}
          </h1>
        ) : (
          <h1 className={styles.allProductList__title}>
            {t("home")}
            <Angle />
            {params.subCatName.replaceAll("-", " ")}
            <Angle />
            {params.childCat.replaceAll("-", " ")}
          </h1>
        )}
        <div className={styles.allProductList__body}>
          {loading
            ? [1, 2, 3, 4].map((item) => {
                return <Skeleton key={item} variant="rectangular" height={350} style={{ borderRadius: "10px" }} />;
              })
            : allProductList?.map((item) => {
                return (
                  <Card
                    id={item.id}
                    key={item.id}
                    title={item.title}
                    src={item.image_id}
                    price={item.discounted_price}
                    original_price={item.outlay_price}
                    discount={item.discount_percnt}
                    subId={atob(params.subId)}
                    data={item}
                  />
                );
              })}
        </div>
      </div>
    </Container>
  );
};

export default AllProductList;
