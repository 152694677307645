import React, { useEffect } from "react";
import contact from "../../assets/contact.gif";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const FloatingContactBtn = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const langDet = JSON.parse(localStorage.getItem("langDet"));

  const onlanguagechange = (l, direction) => {
    const langDet = {
      l: l,
      direction: direction,
    };
    i18n.changeLanguage(l, () => {
      i18n.dir(direction);
    });
    document.body.dir = i18n.dir();
    localStorage.setItem("langDet", JSON.stringify(langDet));
  };

  useEffect(() => {
    if (langDet) {
      i18n.changeLanguage(langDet.l, () => {
        i18n.dir(langDet.direction);
      });
      document.body.dir = langDet.direction;
    }
    // eslint-disable-next-line
  }, []);

  const navigateToContact = () => {
    navigate("/contact-us");
  };
  return (
    <>
      <div onClick={navigateToContact} className={styles.contactBtn}>
        <img className={styles.contactBtn__icon} src={contact} alt="contact" />
        <p className={`${i18n.language === "ar" ? styles.contactBtn__prtl : styles.contactBtn__pltr}`}>{t("contact")}</p>
      </div>
      {i18n.language === "ar" ? (
        <div onClick={() => onlanguagechange("en", "ltr")} className={styles.languageBtn}>
          <p className={styles.languageBtn__pltr}>EN</p>
        </div>
      ) : (
        <div onClick={() => onlanguagechange("ar", "rtl")} className={styles.languageBtn}>
          <p style={{ fontFamily: "Deen" }} className={styles.languageBtn__prtl}>
            ع
          </p>
        </div>
      )}
    </>
  );
};

export default FloatingContactBtn;
