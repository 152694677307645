import React, { useEffect, useState } from "react";
import Container from "../../shared/Container";
import OrderDetails from "./OrderDetails";
import styles from "./index.module.scss";
import KnowAbout from "./KnowAbout";
import Occassion from "./Occassion";
import OrderSummary from "./OrderSummary";
import whatsapp from "../../assets/Whatsapp.gif";
import { useLocation, useNavigate } from "react-router-dom";
import { endpoints } from "../../services/endpoints";
import axios from "axios";
import { uiActions } from "../../utils/reducers/ui";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "../../shared/SweetAlert";
import { useTranslation } from "react-i18next";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let transData = location.state.prodDetails;
  const { t } = useTranslation();

  const userDet = JSON.parse(localStorage.getItem("userDetails"));
  const locDet = JSON.parse(localStorage.getItem("locDet"));
  const token = useSelector((state) => state.auth.token);
  const customizeData = Object.values(transData.selectedCustomization);

  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOccassion, setSelectedOccassion] = useState("");
  const [userdata, setUserdata] = useState({
    name: "",
    phone: "",
    locality: "",
    address: "",
    landmark: "",
    alternatePhone: "",
    pincode: transData.pincode,
    state: "",
    city: locDet.city,
    notes: "",
  });

  useEffect(() => {
    if (transData === undefined) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [transData]);

  const onGetUserDetails = (e, id) => {
    setUserdata((prevVal) => ({ ...prevVal, [id]: e.target.value }));
  };

  const onKnowAbout = (item) => {
    setSelectedPlatform(item.nameEn);
  };

  const onSelectOccassion = (item) => {
    setSelectedOccassion(item.nameEn);
  };

  const onCheckout = (totalPrice) => {
    const api = endpoints.home.bookingDetails;
    setLoading(true);
    if (userdata.name.trim() === "") {
      SweetAlert("warning", "Name Should Not Be Empty");
      setLoading(false);
    } else if (userdata.phone.length !== 10) {
      SweetAlert("warning", "Enter Correct Phone Number");
      setLoading(false);
    } else if (userdata.locality.trim() === "") {
      SweetAlert("warning", "Locality Should Not Be Empty");
      setLoading(false);
    } else if (userdata.address.trim() === "") {
      SweetAlert("warning", "Address Should Not Be Empty");
      setLoading(false);
    } else if (selectedOccassion === "") {
      SweetAlert("warning", "Please Select Occassion Type");
      setLoading(false);
    } else if (selectedPlatform === "") {
      SweetAlert("warning", "Please Select Platform");
      setLoading(false);
    } else {
      if (token) {
        const data = {
          city_id: transData.cityId,
          object_id: transData.objectId,
          pincode: userdata.pincode,
          customization_details: customizeData,
          timeslot_id: transData.timeslotId,
          start_date: transData.booking_date,
          end_date: transData.booking_date,
          object_model: "package",
          status: "processing",
          email: userDet.email,
          first_name: userdata.name,
          last_name: "xrpt",
          gateway: "offline_payment",
          phone: userdata.phone,
          address: userdata.address,
          address2: userdata.locality,
          city: userdata.city,
          // state: userdata.state,
          state: "UP",
          zip_code: userdata.pincode,
          // country: userdata.country,
          country: "India",
          customer_notes: userdata.notes,
          vendor_id: transData.vendorId,
          total_payable: totalPrice,
          occasion_type: selectedOccassion,
          how_know: selectedPlatform,
        };
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        axios
          .post(api, data, { headers: headers })
          .then((res) => {
            if (res.data.status === true) {
              // toast("Thanks for booking !", { type: "success" });
              window.location.replace(res.data.message);
              // console.log(res.data);
              setLoading(false);
            } else if (res.data.status === false) {
              // toast(res.data.message, { type: "error" });
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err, "booking details data not found");
            setLoading(false);
          });
      } else {
        setLoading(false);
        dispatch(
          uiActions.isLoginModalOpen({
            open: true,
          })
        );
      }
    }
  };

  const navigateToWhatsapp = () => {
    window.open("https://api.whatsapp.com/send/?phone=917080581133&text&type=phone_number&app_absent=0", "_blank");
  };

  return (
    <Container>
      <div className={styles.checkout}>
        <div className={styles.checkout__left}>
          <OrderDetails data={transData} onGetUserDetails={onGetUserDetails} userdata={userdata} />
          <KnowAbout onKnowAbout={onKnowAbout} selectedPlatform={selectedPlatform} />
          <Occassion onAboutPlat={onSelectOccassion} />
        </div>
        <div className={styles.checkout__right}>
          <div onClick={navigateToWhatsapp} className={styles.checkout__right__whatsapp}>
            <div className={styles.checkout__right__whatsapp__img}>
              <img src={whatsapp} alt="whatsapp" />
            </div>
            <div className={styles.checkout__right__whatsapp__paras}>
              <p>{t("checkout.whats1")}</p>
              <p>{t("checkout.whats2")}</p>
            </div>
          </div>
          <OrderSummary loading={loading} data={transData} onCheckout={onCheckout} custome={transData.selectedCustomization} />
        </div>
      </div>
    </Container>
  );
};

export default Checkout;
