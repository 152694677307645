import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const Angle = () => {
  const { i18n } = useTranslation();
  return i18n.language === "ar" ? <FaAngleLeft className={styles.angle} /> : <FaAngleRight className={styles.angle} />;
};

export default Angle;
