import React, { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import i18n from "../../i18n";
import axios from "axios";
import { endpoints } from "../../services/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import ChildCat from "./ChildCat";
import Loader from "../Loader";
import { uiActions } from "../../utils/reducers/ui";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const MegaMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [servicName, setServicName] = useState("Birthdays");
  const [facilities, setFacilities] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [masterCategory, setMasterCategory] = useState(servicName);
  const locationDet = JSON.parse(localStorage.getItem("locDet"));
  const location = useSelector((state) => state.ui.cityName);
  const [loading, setLoading] = useState(false);

  const getFilterCategoryList = () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("location_id", locationDet.id);

    const getConfig = {
      url: endpoints.home.filterCategory,
      method: "post",
      data: formdata,
    };
    axios(getConfig)
      .then((res) => {
        if (res.data.status) {
          setFacilities(res.data.body);
          setFacilityList(res.data.body[0].sub_category);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (locationDet?.id) {
      getFilterCategoryList();
    }
    // eslint-disable-next-line
  }, [location?.id]);

  const onShowServiceList = (service, subCategory) => {
    setServicName(service);
    setMasterCategory(service);
    setFacilityList(subCategory);
  };

  const getPackageListByChildCat = async (e, childId, subCatName, childCatName, subId, masterId) => {
    e.stopPropagation();
    let master = masterCategory.replaceAll(" ", "-");
    let sub = subCatName.replaceAll(" ", "-");
    let child = childCatName.replaceAll(" ", "-");
    const path = generatePath("/:location/:masterCat/:masterId/:subCat/:subId/:childCat/:childId", {
      location: location.city,
      masterCat: master,
      masterId: btoa(masterId),
      subCat: sub,
      subId: btoa(subId),
      childCat: child,
      childId: btoa(childId),
    });
    dispatch(
      uiActions.onOpenMegaMenu({
        isOpen: false,
      })
    );
    navigate(path);
  };

  return (
    <div className={Styles.modalArea}>
      {loading ? (
        <div className={Styles.modalArea__loader}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={Styles.modalArea__services}>
            <h2 style={{ textAlign: `${i18n.language === "ar" ? "right" : "left"}` }}>
              {i18n.language === "ar" ? "منشأتنا" : "Our Facilities"}
            </h2>
            <div>
              <ul>
                {facilities.map((item, index) => {
                  return (
                    <li
                      key={index}
                      style={{
                        borderLeft: `${
                          item.name.replace(/Xperience[s]?/i, "").trim() === servicName && i18n.language === "en"
                            ? "5px solid #15124b"
                            : ""
                        }`,
                        borderRight: `${
                          item.name.replace(/Xperience[s]?/i, "").trim() === servicName && i18n.language === "ar"
                            ? "5px solid #15124b"
                            : ""
                        }`,
                      }}
                      onClick={() => onShowServiceList(item.name.replace(/Xperience[s]?/i, "").trim(), item.sub_category)}>
                      {item.name.replace(/Xperience[s]?/i, "").trim()}
                      {i18n.language === "ar" ? <FaAngleLeft /> : <FaAngleRight />}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <ChildCat facilityList={facilityList} getPackageListByChildCat={getPackageListByChildCat} />
        </>
      )}
    </div>
  );
};

export default MegaMenu;
