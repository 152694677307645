import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const InputFieldWithLabel = (props) => {
  const { i18n } = useTranslation();
  return (
    <div style={{ position: "relative", marginBottom: "20px" }}>
      <label
        className={styles.label}
        style={{ left: `${i18n.language === "en" ? "10px" : ""}`, right: `${i18n.language === "ar" ? "10px" : ""}` }}
        htmlFor="">
        {props.label}
      </label>
      <input
        onChange={props.onChange}
        className={styles.input}
        type={props.number}
        id={props.id}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default InputFieldWithLabel;
