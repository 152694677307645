import React from "react";
import styles from "./index.module.scss";
import Title from "../Title";

const RefundPolicy = ({ refundPolicy, title }) => {
  return (
    <div className={styles.refund}>
      <Title title={title} />
      <div className={styles.refund__content} dangerouslySetInnerHTML={{ __html: refundPolicy }}></div>
    </div>
  );
};

export default RefundPolicy;
