import React, { useEffect, useState } from "react";
import DesktopNav from "../../components/DesktopNav";
import Footer from "../../components/Footer";
import FloatingContactBtn from "../../components/FloatingContactBtn";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../utils/reducers/ui";
import Login from "../../modals/Login";
import { useTranslation } from "react-i18next";
import SweetAlert from "../../shared/SweetAlert";
import { authAction } from "../../utils/reducers/auth";
import axios from "axios";
import { endpoints } from "../../services/endpoints";

const Public = ({ element }) => {
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.ui.openLoginModal);

  let user = JSON.parse(localStorage.getItem("user"));
  const phoneNo = JSON.parse(localStorage.getItem("phoneNo"));
  const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    scrollToTop();
  }, [pathname]);

  const onLoginOpen = () => {
    dispatch(
      uiActions.isLoginModalOpen({
        open: true,
      })
    );
  };

  const onUserLogin = (mob) => {
    setLoading(true);
    const url = endpoints.authentication.otpLogin;
    if (mob === "") {
      setLoading(false);
      SweetAlert("error", t("alerts.phone.one"));
    } else if (mob.length !== 10) {
      setLoading(false);
      SweetAlert("error", t("alerts.phone.two"));
    } else {
      const val = {
        phone: `91${mob}`,
      };

      const headers = {
        "Content-type": "application/json; charset=UTF-8",
      };

      axios
        .post(url, val, { headers: headers })
        .then((res) => {
          if (res.data.status === true) {
            setLoading(false);
            const register = res.data?.is_user_register;
            const resgisterDet = {
              isRegister: register,
              mob: mob,
            };

            localStorage.setItem("user", JSON.stringify(resgisterDet));

            dispatch(
              authAction.clickedOnLogin({
                clicked: true,
              })
            );

            SweetAlert("success", t("alerts.otp.one"));
          } else if (res.data.status === false) {
            SweetAlert("error", t("alerts.otp.two"));
          }
        })
        .catch((err) => {
          SweetAlert("error", t("alerts.otp.three"));
          console.log(err, "Otp Login Api Failed");
        });
    }
  };

  const verifyUserOtp = (otp) => {
    //  let otp = one + two + three + four;
    setOtpLoading(true);
    if (otp === "") {
      SweetAlert("error", t("alerts.otp.four"));
      setOtpLoading(false);
    } else if (otp.length !== 4) {
      SweetAlert("error", t("alerts.otp.five"));
      setOtpLoading(false);
    } else {
      const verifyOtpUrl = endpoints.authentication.otpVerify;

      const val = {
        phone: `91${phoneNo}`,
        otp: otp,
      };

      const headers = {
        "Content-type": "application/json; charset=UTF-8",
      };

      if (user.isRegister === "2") {
        axios
          .post(verifyOtpUrl, val, { headers: headers })
          .then((res) => {
            if (res.data.status === true) {
              if (res.data.registration === 2) {
                const token = res.data.body.access_token;
                const userDetails = res.data.body.user;

                localStorage.setItem("userDetails", JSON.stringify(userDetails));
                localStorage.setItem("access_token", token);

                dispatch(authAction.onSetToken(token));
                setOtpLoading(false);
                dispatch(
                  uiActions.isLoginModalOpen({
                    open: false,
                  })
                );
                dispatch(
                  uiActions.userIsLoggedIn({
                    loggedIn: true,
                  })
                );
                SweetAlert("success", t("alerts.user.login"));
              }
            } else if (res.data.status === false) {
              setOtpLoading(false);
              SweetAlert("error", res.data.message);
            }
          })
          .catch((err) => {
            setOtpLoading(false);
            console.log(err, "this is the error which we are  getting here");
          });
      } else if (user.isRegister === "1") {
        localStorage.setItem("otp", otp);
        dispatch(
          authAction.letCheckUserExist({
            isExist: true,
          })
        );
        dispatch(
          authAction.clickedOnLogin({
            clicked: false,
          })
        );
        setOtpLoading(false);
      }
    }
  };

  useEffect(() => {
    if (access_token) {
      dispatch(authAction.onSetToken(access_token));
    }
    // eslint-disable-next-line
  }, [access_token]);

  return (
    <>
      {isOpen && <Login onUserLogin={onUserLogin} loading={loading} verifyUserOtp={verifyUserOtp} otpLoading={otpLoading} />}
      <DesktopNav onLoginOpen={onLoginOpen} />
      {element}
      <Footer />
      <FloatingContactBtn />
    </>
  );
};

export default Public;
