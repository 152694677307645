import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Button from "../../../widgets/Button";
import { options } from "../../../services/objects";
import { getVendorsType, submitMarriageData } from "../../../services/server";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";
import SweetAlert from "../../../shared/SweetAlert";
import { uiActions } from "../../../utils/reducers/ui";
import { useDispatch } from "react-redux";

const Form = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const [vendors, setVendors] = useState([]);
  const [selectedVendorType, setSelectedVendorType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [marriageData, setMarriageData] = useState({
    firstPartner: "",
    secondPartner: "",
    date: "",
    budget: "",
    guests: "",
    city: "",
  });

  const getVendors = async () => {
    setLoading(true);
    const data = await getVendorsType();
    if (data.status) {
      setVendors(data.body);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVendors();
  }, []);

  const onMarriageData = (id, e) => {
    setMarriageData((prev) => ({ ...prev, [id]: e.target.value }));
  };

  const clearFields = () => {
    setMarriageData({
      firstPartner: "",
      secondPartner: "",
      date: "",
      guests: 0,
      city: "",
      // country: "India",
    });
  };

  const onSubmitForm = async () => {
    if (!marriageData?.firstPartner) {
      SweetAlert("error", "Please enter name");
    } else if (!marriageData?.secondPartner) {
      SweetAlert("error", "Please enter partner name");
    } else if (!marriageData?.date) {
      SweetAlert("error", "Please select date of wedding");
    } else if (!marriageData?.budget) {
      SweetAlert("error", "Please enter budget");
    } else if (!marriageData?.city) {
      SweetAlert("error", "Please enter city name");
    } else if (marriageData.budget < 1) {
      SweetAlert("error", "Please Enter Valid Budget Amount");
    } else if (marriageData.guests < 1) {
      SweetAlert("error", "Please Enter Valid Guest Number");
    } else {
      setFormSubmit(true);
      const params = {
        partner_one: marriageData.firstPartner,
        partner_two: marriageData.secondPartner,
        date: marriageData.date,
        budget: marriageData.budget,
        number_of_gifts: marriageData.guests,
        city: marriageData.city,
        country: "India",
        wedding_vendors: selectedVendorType,
      };

      const data = await submitMarriageData(params);

      if (data.status) {
        clearFields();
        dispatch(
          uiActions.isMarriagePopupOpen({
            isTrue: false,
          })
        );
        SweetAlert("success", "Your Enquiry submitted");
      } else {
        setFormSubmit(false);
      }
    }
  };

  const onGetVendors = (e) => {
    if (selectedVendorType.includes(e.target.value)) {
      const filterArray = selectedVendorType.filter((item) => {
        return item !== e.target.value;
      });
      setSelectedVendorType(filterArray);
    } else {
      setSelectedVendorType((item) => {
        return [...item, e.target.value];
      });
    }
  };

  const date = new Date();
  let month = date.getMonth() + 1;
  let currDate = `${date.getFullYear()}-${month > 9 ? month : "0" + month}-${date.getDate()}`;

  return (
    <div className={styles.form}>
      <div className={styles.form__top}>
        <div className={styles.form__top__left}>
          <p>{t("mPortal.iam")}</p>
          <div className={styles.form__top__left__field}>
            <input onChange={(e) => onMarriageData("firstPartner", e)} id="iam" type="text" />
            <select>
              {options.map((item) => {
                return (
                  <option selected={item.nameEn === "Choose"} value={item.nameEn}>
                    {i18n.language === "ar" ? item.nameAr : item.nameEn}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className={styles.form__top__right}>
          <p>{t("mPortal.myP")}</p>
          <div className={styles.form__top__right__field}>
            <input onChange={(e) => onMarriageData("secondPartner", e)} id="mypartner" type="text" />
            <select>
              {options.map((item) => {
                return (
                  <option selected={item.nameEn === "Choose"} value={item.nameEn}>
                    {i18n.language === "ar" ? item.nameAr : item.nameEn}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <h2>{t("mPortal.wedDetails")}</h2>
      <div className={styles.form__bottom}>
        <div className={styles.form__bottom__top}>
          <div>
            <p>{t("mPortal.date")}</p>
            <input min={currDate} onChange={(e) => onMarriageData("date", e)} id="date" type="date" />
          </div>
          <div>
            <p>{t("mPortal.nOfGuest")}</p>
            <input min="1" onChange={(e) => onMarriageData("guests", e)} id="guest" type="number" placeholder="0" />
          </div>
          <div>
            <p>{t("mPortal.budget")}</p>
            <input
              min="1"
              id="budget"
              onChange={(e) => onMarriageData("budget", e)}
              type="number"
              placeholder={t("mPortal.enterB")}
            />
          </div>
          <div>
            <p>{t("mPortal.cityT")}</p>
            <input onChange={(e) => onMarriageData("city", e)} id="city" type="text" placeholder={t("mPortal.enterC")} />
          </div>
          <div>
            <p>{t("mPortal.country")}</p>
            <input id="country" type="text" value="INDIA" disabled />
          </div>
        </div>
      </div>
      <h2>{t("mPortal.vendorT")}</h2>

      {loading ? (
        <div className={styles.form__mid__loader}>
          <Loader />
        </div>
      ) : (
        <div className={styles.form__mid}>
          {vendors.map((item) => {
            return (
              <div key={item.id} className={styles.form__mid__field}>
                <input onChange={(e) => onGetVendors(e)} value={item.vendor_en} type="checkbox" />
                <p>{i18n.language === "en" ? item.vendor_en : item.vendor_ar}</p>
              </div>
            );
          })}
        </div>
      )}
      <div className={styles.form__btn}>
        <Button name={t("tPortal.submit")} onClick={onSubmitForm} loading={formSubmit} />
      </div>
    </div>
  );
};

export default Form;
