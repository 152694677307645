import { useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "./utils/reducers/ui";

import Home from "./pages/Home";
import Public from "./routes/Public";
import Gifts from "./pages/Gifts";
import ProductDetails from "./pages/ProductDetails";
import AllProductList from "./pages/AllProductList";
import AllCategoriesProductList from "./pages/AllCategoriesProductList";
import OffersProducts from "./shared/OffersProducts";
import Contact from "./pages/ContactUs";
import Favourites from "./pages/Favourites";
// import UpcomingBookings from "./pages/UpcomingBookings";
// import PastBookings from "./pages/PastBookings";
import Checkout from "./pages/Checkout";
import { getFavouritesList } from "./services/server";
import { productsActions } from "./utils/reducers/product";
import WhoWeAre from "./pages/WhoWeAre";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import SeeAllLists from "./pages/SeeAllLists";
import Bookings from "./pages/Bookings";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Private from "./routes/Private";
import Invoice from "./pages/Invoice";
import ThankYou from "./pages/ThankYou";
import Extra from "./routes/Extra";
import NotFound from "./pages/NotFound";
import { authAction } from "./utils/reducers/auth";

function App() {
  const dispatch = useDispatch();

  // const access_token = localStorage.getItem("access_token");
  const location = JSON.parse(localStorage.getItem("locDet"));
  const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
  const token = useSelector((state) => state.auth.token);
  const isFav = useSelector((state) => state.products.isFavrtExist);
  const triggered = useSelector((state) => state.auth.triggered);

  useEffect(() => {
    if (!location) {
      dispatch(
        uiActions.openLocationModal({
          isTrue: true,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (access_token) {
  //     dispatch(authAction.onSetToken(access_token));
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(
        uiActions.userIsLoggedIn({
          loggedIn: isLoggedIn,
        })
      );
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const getLatestFavrtList = async () => {
    const res = await getFavouritesList();

    if (res?.status) {
      dispatch(
        productsActions.allWishList({
          favrtList: res.body,
        })
      );
    }
  };

  useEffect(() => {
    if (token) getLatestFavrtList();
    // eslint-disable-next-line
  }, [isFav]);
  useEffect(() => {
    dispatch(authAction.onLoading(true));

    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };

    if (token) {
      fetch("https://admin.xperienceit.in/api/userProfiles", {
        headers: headers,
        method: "POST",
      })
        .then((rs) => rs.json())
        .then((result) => {
          if (result.status === true) {
            const data = result.body;
            console.log(data);
            dispatch(authAction.setProfile(data.profileImg));
            dispatch(authAction.onGetUserDetails(data));
            dispatch(authAction.onLoading(false));
          } else if (result.status === false) {
            dispatch(authAction.onLoading(false));
          }
        })
        .catch((err) => {
          console.log(err, "this is th error");
          dispatch(authAction.onLoading(false));
        });
    }
    console.log("userProfile");
    // eslint-disable-next-line
  }, [triggered, token]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Public element={<Home />} />} />
        <Route path="/gifts" element={<Public element={<Gifts />} />} />
        <Route path="/:location/offers/:offerName/:offerId/:name/:prodId" element={<Public element={<ProductDetails />} />} />
        <Route path="/:location/:masterCat/:masterId/:subId/:name/:prodId" element={<Public element={<ProductDetails />} />} />
        <Route
          path="/:location/:masterCat/:masterId/:subCat/:subId/:childCat/:childId/:name/:prodId"
          element={<Public element={<ProductDetails />} />}
        />
        <Route
          path="/:location/searches/:subCatName/:subId/:childCat/:childId/:name/:prodId"
          element={<Public element={<ProductDetails />} />}
        />
        <Route
          path="/:location/:masterCat/:masterId/:subCat/:subId/:childCat/:childId"
          element={<Public element={<AllProductList />} />}
        />
        <Route path="/:location/:masterCat/:masterId" element={<Public element={<AllCategoriesProductList />} />} />
        <Route
          path="/:location/searches/:subCatName/:subId/:childCat/:childId"
          element={<Public element={<AllProductList />} />}
        />
        <Route path="/:location/offers/:offerName/:offerId" element={<Public element={<OffersProducts />} />} />
        <Route path="/contact-us" element={<Public element={<Contact />} />} />
        <Route path="/wishlists" element={<Private element={<Favourites />} />} />
        <Route path="/:location/:prodName/:prodId/booking" element={<Public element={<Checkout />} />} />
        {/* <Route path="/upcoming-bookings" element={<Private element={<UpcomingBookings />} />} />
        <Route path="/past-bookings" element={<Private element={<PastBookings />} />} /> */}
        <Route path="/who-we-are" element={<Public element={<WhoWeAre />} />} />
        <Route path="/blogs" element={<Public element={<Blogs />} />} />
        <Route path="/bookings" element={<Private element={<Bookings />} />} />
        <Route path="/blog-details/:title" element={<Public element={<BlogDetails />} />} />
        <Route path="/xperiences/:type/packages" element={<Public element={<SeeAllLists />} />} />
        <Route path="/terms-and-condition" element={<Public element={<Terms />} />} />
        <Route path="/privacy-policy" element={<Public element={<Privacy />} />} />
        <Route path="/invoice" element={<Private element={<Invoice />} />} />
        <Route path="/thank-you/:orderId" element={<Private element={<ThankYou />} />} />
        <Route path="*" element={<Extra element={<NotFound />} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
