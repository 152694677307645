import React, { useState } from "react";
import styles from "./index.module.scss";
import fb from "../../../assets/svgIcons/facebook.svg";
import mail from "../../../assets/svgIcons/mail.svg";
import google from "../../../assets/svgIcons/google.svg";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import ButtonTwo from "../../../widgets/ButtonTwo";
import { uiActions } from "../../../utils/reducers/ui";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../../../utils/reducers/auth";
import InputFieldWithLabel from "../../../shared/InputFieldWithLabel";
import { endpoints } from "../../../services/endpoints";
import axios from "axios";
import SweetAlert from "../../../shared/SweetAlert";
import { useNavigate } from "react-router-dom";

const LogComp = ({ onUserLogin, loading }) => {
  const navigate = useNavigate();

  const [mob, setMob] = useState("");
  const [loginProgress, setLoginProgress] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
  });
  const onetime = localStorage.getItem("otp");
  const otp = onetime && JSON.parse(onetime);
  const phone = localStorage.getItem("phoneNo");
  const phoneNo = phone && JSON.parse(phone);

  const dispatch = useDispatch();

  const { i18n, t } = useTranslation();

  const isUserExist = useSelector((state) => state.auth.isUserExist);

  const closeModal = () => {
    dispatch(
      uiActions.isLoginModalOpen({
        open: false,
      })
    );
    // dispatch(
    //   authAction.clickedOnLogin({
    //     clicked: true,
    //   })
    // );
    dispatch(
      authAction.letCheckUserExist({
        isExist: false,
      })
    );
  };

  const getMobileNumber = (e) => {
    setMob(e.target.value);
  };

  const getOtherDetails = (e, id) => {
    setUserData((prevState) => ({
      ...prevState,
      [id]: e.target.value,
    }));
  };

  const onLoginClicked = () => {
    localStorage.setItem("phoneNo", mob);
    onUserLogin(mob);
  };

  function validateEmail(email) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }

  const userLoginSuccessfullyWithOtp = () => {
    setLoginProgress(true);
    if (userData.name === "") {
      setLoginProgress(false);
      SweetAlert("error", t("alerts.user.one"));
    } else if (userData.name.length < 4) {
      setLoginProgress(false);
      SweetAlert("error", t("alerts.user.two"));
    } else if (userData.email === "") {
      setLoginProgress(false);
      SweetAlert("error", t("alerts.user.three"));
    } else if (!validateEmail(userData.email)) {
      setLoginProgress(false);
      SweetAlert("error", t("alerts.user.four"));
    } else {
      const api = endpoints.authentication.otpVerify;
      const val = {
        name: userData.name,
        email: userData.email,
        phone: `91${phoneNo}`,
        otp: otp,
      };
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
      };
      axios
        .post(api, val, { headers: headers })
        .then((res) => {
          if (res.data.status === true) {
            const token = res.data.body.access_token;
            const userDetails = res.data.body.user;

            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            localStorage.setItem("access_token", token);

            setLoginProgress(false);
            dispatch(
              uiActions.isLoginModalOpen({
                open: false,
              })
            );
            dispatch(
              uiActions.userIsLoggedIn({
                loggedIn: true,
              })
            );
            SweetAlert("success", t("alerts.user.login"));
          } else if (res.data.status === false) {
            setLoginProgress(false);
            SweetAlert("error", t("alerts.user.five"));
          }
        })
        .catch((err) => {
          SweetAlert("error", t("alerts.user.six"));
          console.log(err, " Sign In Failed");
        });
    }
  };

  const onNavigateToTerms = () => {
    navigate("/terms-and-condition");
    dispatch(
      uiActions.isLoginModalOpen({
        open: false,
      })
    );
  };

  const onNavigateToPrivacy = () => {
    navigate("/privacy-policy");
    dispatch(
      uiActions.isLoginModalOpen({
        open: false,
      })
    );
  };

  return (
    <div className={styles.container}>
      <span onClick={closeModal} className={styles.container__close}>
        <IoMdClose />
      </span>

      <div className={styles.container__heading}>{t("login")}</div>
      <div className={styles.container__form}>
        {isUserExist ? (
          <>
            <InputFieldWithLabel
              onChange={(e) => getOtherDetails(e, "name")}
              id="name"
              type="text"
              label={i18n.language === "ar" ? "رقم التليفون" : "Full Name"}
              placeholder={i18n.language === "ar" ? "أدخل رقم الجوال" : "Enter Your Name"}
            />
            <InputFieldWithLabel
              onChange={(e) => getOtherDetails(e, "email")}
              id="email"
              type="email"
              label={i18n.language === "ar" ? "رقم التليفون" : "Email ID"}
              placeholder={i18n.language === "ar" ? "أدخل رقم الجوال" : "Enter Your Email ID"}
            />
            <ButtonTwo
              onClick={userLoginSuccessfullyWithOtp}
              name={isUserExist ? t("login") : t("viaOTP")}
              loading={loginProgress}
            />
          </>
        ) : (
          <>
            <InputFieldWithLabel
              onChange={getMobileNumber}
              id="mobile"
              type="number"
              label={i18n.language === "ar" ? "رقم التليفون" : "Mobile Number"}
              placeholder={i18n.language === "ar" ? "أدخل رقم الجوال" : "Enter 10-Digit Mobile Number"}
            />
            <ButtonTwo onClick={onLoginClicked} name={isUserExist ? t("login") : t("viaOTP")} loading={loading} />
          </>
        )}
      </div>
      <div className={styles.container__socialAccountContainer}>
        <span className={styles.container__socialAccountContainer__title}>{t("orRegister")}</span>
        <div className={styles.container__socialAccountContainer__socialAccounts}>
          <div className={styles.container__socialAccountContainer__socialAccounts__socialButton}>
            <img src={google} alt="google" />
          </div>
          <div className={styles.container__socialAccountContainer__socialAccounts__socialButton}>
            <img src={fb} alt="fb" />
          </div>
          <div className={styles.container__socialAccountContainer__socialAccounts__socialButton}>
            <img src={mail} alt="mail" />
          </div>
        </div>
      </div>
      <span className={styles.container__agreement}>
        <p>
          {t("paraLogging")} <br /> <span onClick={onNavigateToTerms}>{t("terms")}</span> {i18n.language === "ar" ? "و" : "and"}{" "}
          <span onClick={onNavigateToPrivacy}>{t("privacy")}</span>
        </p>
      </span>
    </div>
  );
};

export default LogComp;
