import React, { useEffect, useState } from "react";
import Container from "../../shared/Container";
import styles from "./index.module.scss";
import upcomingBookings from "../../assets/notData.svg";
import BookingCard from "../../components/BookingCard";
import { endpoints } from "../../services/endpoints";
import axios from "axios";
import Loader from "../../components/Loader";
import Details from "../../modals/Details";
import { getBookingDetails, onCancelBooking, onCancelReason } from "../../services/server";
import CancelOrder from "../../modals/CancelOrder";
import SweetAlert from "../../shared/SweetAlert";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const UpcomingBookings = () => {
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [bookingId, setBookingId] = useState("");
  const [cancelReason, setCancelReason] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailsLoad, setDetailsLoad] = useState(false);
  const [cancelLoad, setCancelLoad] = useState(false);

  const { t } = useTranslation();

  const access_token = localStorage.getItem("access_token");
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    setLoading(true);
    if (access_token) {
      const url = endpoints.booking.upcoming;

      axios
        .get(url, { headers: { Authorization: `Bearer ${access_token}` } })
        .then((res) => {
          if (res.data.status) {
            setLoading(false);
            setData(res.data.body);
          } else {
            setData([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  const openDetailsModal = async (itm) => {
    setBookingId(itm.booking_id);
    setDetailsLoad(true);
    setOpenDetail(true);

    const formdata = new FormData();
    formdata.append("booking_id", itm.booking_id);

    const res = await getBookingDetails(formdata, token);
    if (res.status) {
      setBookingData(res.body[0]);
      setDetailsLoad(false);
    } else {
      setBookingData([]);
      setDetailsLoad(false);
    }
  };

  const closeDetailModal = () => {
    setOpenDetail(false);
  };

  const openCancelModal = () => {
    // setOpenDetail(true);
    setOpenCancel(false);
  };

  const openCancelReason = async () => {
    setCancelLoad(true);
    setOpenCancel(true);
    setOpenDetail(false);

    const res = await onCancelReason();

    if (res.status) {
      setCancelReason(res.body);
      setCancelLoad(false);
    } else {
      setCancelReason([]);
      setCancelLoad(false);
    }
  };

  const onCancelBookingItem = async () => {
    const formdata = new FormData();
    formdata.append("booking_id", bookingId);
    formdata.append("status", "cancelled");

    const res = await onCancelBooking(formdata, token);

    if (res.status) {
      SweetAlert("success", res.message);
      setOpenCancel(false);
    } else {
      SweetAlert("error", res.message);
      setOpenCancel(false);
    }
  };

  return (
    <>
      {openCancel && (
        <CancelOrder
          id={bookingId}
          items={cancelReason}
          onCancelBookingItem={onCancelBookingItem}
          closeDetailModal={openCancelModal}
          loading={cancelLoad}
        />
      )}
      {openDetail && (
        <Details
          items={bookingData}
          closeDetailModal={closeDetailModal}
          loading={detailsLoad}
          openCancelReason={openCancelReason}
        />
      )}
      <Container>
        <h1 className={styles.h1}>{t("booking.upcoming1")}</h1>
        {loading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <>
            {data.length < 1 && (
              <div className={styles.wishlist}>
                <img src={upcomingBookings} alt="favourite" />
                <h1>{t("booking.noData")}</h1>
              </div>
            )}
            {data.length >= 1 && (
              <div className={styles.wishlist1}>
                {data.map((item, ind) => (
                  <BookingCard
                    key={item.id + ind}
                    title={item.title}
                    date={item.dateMonth}
                    img={item.image_id}
                    purchased={item.purchased_price}
                    reviews={item.reviews}
                    screen="upcoming"
                    rating={item.rating}
                    onClick={() => openDetailsModal(item)}
                    onClick2={openCancelReason}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default UpcomingBookings;
