import React from "react";
import styles from "./index.module.scss";

const Loader = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__dot}>
        <div className={styles.wrapper__dot__circle}></div>
        <div className={styles.wrapper__dot__circle}></div>
        <div className={styles.wrapper__dot__circle}></div>
      </div>
      <div className={styles.wrapper__dotshadow}>
        <div className={styles.wrapper__dotshadow__shadow}></div>
        <div className={styles.wrapper__dotshadow__shadow}></div>
        <div className={styles.wrapper__dotshadow__shadow}></div>
      </div>
    </div>
  );
};

export default Loader;
