import React from "react";
import Modal from "..";
import styles from "./index.module.scss";
import clock from "../../assets/clock.webp";
import Form from "./Form";
import { useDispatch } from "react-redux";
import { uiActions } from "../../utils/reducers/ui";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const Marriage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const closeMarriageModal = () => {
    dispatch(
      uiActions.isMarriagePopupOpen({
        isTrue: false,
      })
    );
  };
  return (
    <Modal onClose={closeMarriageModal} width="70%">
      <div className={styles.marriage}>
        <span
          style={{ right: `${i18n.language === "ar" ? "" : "10px"}`, left: `${i18n.language === "ar" ? "10px" : ""}` }}
          onClick={closeMarriageModal}
          className={styles.marriage__close}>
          <IoMdClose />
        </span>
        <div className={styles.marriage__left}>
          <h2>{t("mPortal.title")}</h2>
          <p>{t("mPortal.para")}</p>
          <div className={styles.marriage__left__img}>
            <img src={clock} alt="clock" />
          </div>
        </div>
        <div className={styles.marriage__right}>
          <h2>{t("mPortal.about")}</h2>
          <Form />
        </div>
      </div>
    </Modal>
  );
};

export default Marriage;
