import React from "react";
import Title from "../Title";
import ReviewCard from "./ReviewCard";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const AllReviews = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.allReviews}>
      <Title title={t("details.reviews")} />
      <div className={styles.allReviews__content}>
        {data?.length > 0 ? (
          data?.map((item) => <ReviewCard key={item.id} data={item} />)
        ) : (
          <p style={{ textAlign: "center", width: "100%" }}>No Reviews</p>
        )}
      </div>
    </div>
  );
};

export default AllReviews;
