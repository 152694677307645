import React, { forwardRef } from "react";
import Title from "../Title";
import styles from "./index.module.scss";

const Details = forwardRef((props, ref) => {
  return (
    <div ref={ref} className={styles.details}>
      <Title title={props.title} />
      <div className={styles.details__content} dangerouslySetInnerHTML={{ __html: props.content }}></div>
    </div>
  );
});

export default Details;
