import React from "react";
import styles from "./index.module.scss";
import SocialLinks from "../../../components/SocialLinks";

const SocialPlatforms = () => {
  return (
    <div className={styles.socialPlatforms}>
      <h2>Follow Us On:</h2>
      <SocialLinks />
    </div>
  );
};

export default SocialPlatforms;
