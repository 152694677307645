import React from "react";
import styles from "./index.module.scss";
import { generatePath, useNavigate } from "react-router-dom";

const OfferCard = (props) => {
  const locationDet = JSON.parse(localStorage.getItem("locDet"));
  const navigate = useNavigate();

  const onGoesToOfferSection = (data) => {
    const name = data?.offer_name;
    const offerName = name?.replaceAll(" ", "-");

    const offerId = data.id;

    const path = generatePath("/:location/offers/:offerName/:offerId", {
      location: locationDet.city,
      offerName: offerName,
      offerId: btoa(offerId),
    });

    navigate(path);
  };

  return (
    <div className={styles.offerCard} onClick={() => onGoesToOfferSection(props.data)}>
      <img src={props.img} alt="offer" />
    </div>
  );
};

export default OfferCard;
