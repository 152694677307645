import React, { useEffect, useState } from "react";
import { getBlogs } from "../../services/server";
import BlogCard from "../../components/BlogCard";
import Container from "../../shared/Container";
import styles from "./index.module.scss";
import { Skeleton } from "@mui/material";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setloading] = useState(true);

  const getData = async () => {
    setloading(true);
    const res = await getBlogs();

    if (res?.status) {
      setBlogs(res.body);
      setloading(false);
    } else {
      setBlogs([]);
      setloading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Container>
      <div className={styles.blogs}>
        {loading ? (
          <div className={styles.blogs__cont}>
            <Skeleton variant="rectangular" height={300} className={styles.blogs__cont__card} />
          </div>
        ) : (
          blogs.map((item) => {
            return (
              <BlogCard key={item.id} src={item.image_id} title={item.title} date={item.date} msg={item.content} data={item} />
            );
          })
        )}
      </div>
    </Container>
  );
};

export default Blogs;
