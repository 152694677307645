import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa6";
import styles from "./index.module.scss";

const SocialLinks = () => {
  return (
    <div className={styles.socialButtons}>
      <a
        href="https://www.facebook.com/xperienceit.in"
        className={`${styles.socialButtons__socialButton} ${styles.socialButtons__facebook}`}
        rel="noreferrer"
        target="_blank">
        <FaFacebook className={styles.socialButtons__facebook__svg} />
      </a>
      <a
        href="https://api.whatsapp.com/send/?phone=7080581133&text&type=phone_number&app_absent=0"
        className={`${styles.socialButtons__socialButton} ${styles.socialButtons__whatsapp}`}
        rel="noreferrer"
        target="_blank">
        <FaWhatsapp className={styles.socialButtons__whatsapp__svg} />
      </a>
      <a
        href="https://www.instagram.com/xperience.it?igshid=1qzstj88x71zy"
        className={`${styles.socialButtons__socialButton} ${styles.socialButtons__instagram}`}
        rel="noreferrer"
        target="_blank">
        <FaInstagram className={styles.socialButtons__instagram__svg} />
      </a>
      <a
        href="https://youtube.com/@xperienceiteventplanner?si=HpzAGvKVNHH5nlUP"
        className={`${styles.socialButtons__socialButton} ${styles.socialButtons__youtube}`}
        rel="noreferrer"
        target="_blank">
        <FaYoutube className={styles.socialButtons__youtube__svg} />
      </a>
    </div>
  );
};

export default SocialLinks;
