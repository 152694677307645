import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Card from "./Card";
import { endpoints } from "../../services/endpoints";
import axios from "axios";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";
import Container from "../../shared/Container";
import Title from "../Title";

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const { i18n } = useTranslation();
  useEffect(() => {
    const url = endpoints.home.testimonials;
    axios
      .get(url)
      .then((res) => {
        if (res.data.status) {
          setTestimonials(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const rtl = i18n.language === "ar" ? true : false;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    laptop: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      partialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 768, min: 480 },
      items: 1,
      partialVisibilityGutter: 100,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={styles.testimonial}>
      <Container>
        <div className={styles.testimonial__top}>
          <Title
            color1="#fff"
            color="#fff"
            title={i18n.language === "ar" ? "الشهادات" : "Testimonials"}
            headline={i18n.language === "ar" ? "العملاء الراضون عنا" : "Satisfied Clients About Us"}
          />
        </div>
        <div className={styles.testimonial__bottom}>
          <Carousel
            rtl={rtl}
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            customTransition="all .5s"
            transitionDuration={500}
            autoPlaySpeed={2000}
            showDots={false}
            infinite={true}
            partialVisible={true}>
            {testimonials.map((testi) => {
              return <Card key={testi.desc} user={testi.avatar} message={testi.desc} />;
            })}
          </Carousel>
        </div>
      </Container>
    </div>
  );
};

export default Testimonial;
