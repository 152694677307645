import React, { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import { endpoints } from "../../services/endpoints";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { uiActions } from "../../utils/reducers/ui";
import Loader from "../Loader";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Acordian = () => {
  const [tab, setTab] = useState("Birthdays");
  const [serviceTab, setServicTab] = useState("");
  const [facilities, setFacilities] = useState([]);
  const location = useSelector((state) => state.ui.cityName);
  const locationDet = JSON.parse(localStorage.getItem("locDet"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [delay, setDelay] = useState(true);
  //   *****************************************************************
  const getFilterCategoryList = () => {
    const formdata = new FormData();
    formdata.append("location_id", locationDet.id);

    const getConfig = {
      url: endpoints.home.filterCategory,
      method: "post",
      data: formdata,
    };
    axios(getConfig)
      .then((res) => {
        if (res.data.status) {
          setFacilities(res.data.body);
          //   setFacilityList(res.data.body[0].sub_category);
          setDelay(false);
        }
      })
      .catch((err) => console.log("Error", err));
  };

  useEffect(() => {
    if (locationDet?.id) {
      getFilterCategoryList();
    }
    // eslint-disable-next-line
  }, [location?.id]);

  const openTab = (index) => {
    if (tab === index) {
      setTab(null);
    } else {
      setTab(index);
    }
    setServicTab("");
  };
  const openService = (item) => {
    if (serviceTab === item) {
      setServicTab("");
    } else {
      setServicTab(item);
    }
  };
  const getPackageListByChildCat = async (childId, subCatName, childCatName, subId, masterId) => {
    let master = tab.replaceAll(" ", "-");
    let sub = subCatName.replaceAll(" ", "-");
    let child = childCatName.replaceAll(" ", "-");
    const path = generatePath("/:location/:masterCat/:masterId/:subCat/:subId/:childCat/:childId", {
      location: location.city,
      masterCat: master,
      masterId: btoa(masterId),
      subCat: sub,
      subId: btoa(subId),
      childCat: child,
      childId: btoa(childId),
    });

    navigate(path);
    dispatch(
      uiActions.showMenu({
        isShoMenu: false,
      })
    );
  };

  const onNavigateToHome = () => {
    navigate("/");
  };

  const onNavigateToGifts = () => {
    navigate("/gifts");
  };

  const openMarriageForm = () => {
    dispatch(
      uiActions.isMarriagePopupOpen({
        isTrue: true,
      })
    );
  };
  return (
    <>
      {delay ? (
        <div className={Styles.loder}>
          <Loader />
        </div>
      ) : (
        <div className={Styles.acordian}>
          <p onClick={onNavigateToHome} className={Styles.main}>
            {t("home")}
          </p>
          <p onClick={onNavigateToGifts} className={Styles.main}>
            {t("gifts")}
          </p>
          <p onClick={openMarriageForm} className={Styles.main}>
            {t("marriage")}
          </p>
          {facilities.map((item, ind) => {
            return (
              <div key={ind}>
                <div className={Styles.acordian__hedding}>
                  <div
                    className={Styles.acordian__hedding__contant}
                    onClick={() => openTab(item.name.replace(/Xperience[s]?/i, "").trim())}>
                    <p>{item.name.replace(/Xperience[s]?/i, "").trim()}</p>
                    {tab !== item.name.replace(/Xperience[s]?/i, "").trim() ? (
                      <FaAngleRight className={Styles.acordian__hedding__contant__icon} />
                    ) : (
                      <FaAngleDown className={Styles.acordian__hedding__contant__icon} />
                    )}
                  </div>

                  <div className={Styles.acordian__hedding__SubContent}>
                    {tab === item.name.replace(/Xperience[s]?/i, "").trim() && (
                      <div className={Styles.acordian__contantArea}>
                        <ul>
                          {item.sub_category.map((item2) => {
                            return (
                              <>
                                <li onClick={() => openService(item2.id)}>
                                  {item2.name}
                                  {serviceTab !== item2.id ? (
                                    <FaAngleRight className={Styles.acordian__hedding__contant__icon} />
                                  ) : (
                                    <FaAngleDown className={Styles.acordian__hedding__contant__icon} />
                                  )}
                                </li>
                                {serviceTab === item2.id && (
                                  <>
                                    <ul>
                                      {item2.child_category.map((item3, index) => {
                                        return (
                                          <li
                                            key={index}
                                            onClick={() =>
                                              getPackageListByChildCat(
                                                item3.id,
                                                item2.name,
                                                item3.name,
                                                item3.subcategory_id,
                                                item2.parent_id
                                              )
                                            }>
                                            {item3.name}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </>
                                )}
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Acordian;
