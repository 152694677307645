import React from "react";
import styles from "./index.module.scss";

const Input = (props) => {
  return (
    <div style={props.styleCont} className={styles.inputCont}>
      {/* {props.label && (
        <label
          style={{ left: i18n.language === "ar" ? "" : "10px", right: i18n.language === "ar" ? "10px" : "" }}
          htmlFor={props.id}>
          {props.label}
        </label>
      )} */}
      <input
        className={styles.inputCont__input}
        onFocus={props.onFocus}
        onChange={props.onChange}
        type={props.type}
        id={props.id}
        placeholder={props.label}
        value={props.value}
        min={props.min}
        max={props.max}
        disabled={props.disabled}
        style={props.style}
      />
    </div>
  );
};

export default Input;
