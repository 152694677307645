import React from "react";
import styles from "./index.module.scss";

const Title = ({ title }) => {
  return (
    <h2 className={styles.title}>
      {title} <span>*</span>
    </h2>
  );
};

export default Title;
