import React from "react";
import Card from "../Card";
import styles from "./index.module.scss";
import Carousel from "react-multi-carousel";
import SeeAll from "../SeeAll";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import Container from "../../shared/Container";
import Title from "../Title";
import { generatePath, useNavigate } from "react-router-dom";

const Packages = ({ items, heading, loading }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const rtl = i18n.language === "ar" ? true : false;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    laptop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      partialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 768, min: 480 },
      items: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
      partialVisibilityGutter: 100,
    },
  };

  const onSeeAllProducts = () => {
    const path = generatePath("/xperiences/:type/packages", {
      type: heading.replaceAll(" ", "-"),
    });

    navigate(path, { state: { allLists: items } });
  };

  return (
    <Container>
      <div className={styles.packages}>
        <div className={styles.packages__top}>
          {/* <Title title={i18n.language === "ar" ? "تسوق حسب" : "Shop By"} headline={heading} /> */}
          <Title headline={heading} />
          <SeeAll onClick={onSeeAllProducts} />
        </div>
        {loading ? (
          <div className={styles.packages__container1}>
            {[1, 2, 3, 4].map((item, index) => {
              return <Skeleton key={index} variant="rectangular" height={350} width={300} style={{ borderRadius: "10px" }} />;
            })}
          </div>
        ) : (
          <div className={styles.packages__container}>
            <Carousel
              className="package"
              rtl={rtl}
              responsive={responsive}
              autoPlay={true}
              swipeable={true}
              draggable={true}
              customTransition="all .5s"
              transitionDuration={500}
              autoPlaySpeed={2000}
              showDots={false}
              infinite={true}
              partialVisible={true}>
              {items?.map((item) => {
                return (
                  <div key={item.id} className={styles.packages__container__packageSlider}>
                    <Card
                      margin="0 20px"
                      id={item.id}
                      title={item.title}
                      src={item.image_id}
                      price={item.discounted_price}
                      original_price={item.outlay_price}
                      discount={item.discount_percnt}
                      data={item}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Packages;
