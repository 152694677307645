import React, { useState, useRef } from "react";
import styles from "./index.module.scss";
import { IoMdClose } from "react-icons/io";
import ButtonTwo from "../../widgets/ButtonTwo";
import { useDispatch } from "react-redux";
import { authAction } from "../../utils/reducers/auth";
import { useTranslation } from "react-i18next";

const OtpScreen = ({ verifyUserGivenOtp, loading }) => {
  // const [loading, setloading] = useState(false);
  const { t } = useTranslation();
  const firstInputRef = useRef(null);
  const secondInputRef = useRef(null);
  const thirdInputRef = useRef(null);
  const forthInputRef = useRef(null);
  const [one, setOne] = useState("");
  const [two, setTwo] = useState("");
  const [three, setThree] = useState("");
  const [four, setFour] = useState("");
  const dispatch = useDispatch();

  // let user = JSON.parse(localStorage.getItem("user"));
  // const phoneNo = JSON.parse(localStorage.getItem("phoneNo"));

  const style = {
    backgroundColor: "#ffab02",
    color: "#fff",
    border: "1px solid #ffab02",
  };

  const unstyle = {
    backgroundColor: "",
    color: "",
    border: "",
  };

  const firstNum = (e) => {
    setOne(e.target.value);
    if (e.target.value.length === 1) {
      secondInputRef.current.focus();
    }
  };
  const secondNum = (e) => {
    setTwo(e.target.value);
    if (e.target.value.length === 1) {
      thirdInputRef.current.focus();
    }
    if (e.target.value.length === 0) {
      firstInputRef.current.focus();
    }
  };
  const thirdNum = (e) => {
    setThree(e.target.value);
    if (e.target.value.length === 1) {
      forthInputRef.current.focus();
    }
    if (e.target.value.length === 0) {
      secondInputRef.current.focus();
    }
  };
  const forthNum = (e) => {
    setFour(e.target.value);
    if (e.target.value.length === 0) {
      thirdInputRef.current.focus();
    }
  };

  const closeOtpModal = () => {
    dispatch(
      authAction.clickedOnLogin({
        clicked: false,
      })
    );
  };

  const verifyUserOtp = () => {
    let otp = one + two + three + four;
    verifyUserGivenOtp(otp);
  };

  // const verifyUserOtp = () => {
  //   let otp = one + two + three + four;
  //   setloading(true);
  //   if (otp === "") {
  //     SweetAlert("error", t("alerts.otp.four"));
  //     setloading(false);
  //   } else if (otp.length !== 4) {
  //     SweetAlert("error", t("alerts.otp.five"));
  //     setloading(false);
  //   } else {
  //     const verifyOtpUrl = endpoints.authentication.otpVerify;

  //     const val = {
  //       phone: `91${phoneNo}`,
  //       otp: otp,
  //     };

  //     const headers = {
  //       "Content-type": "application/json; charset=UTF-8",
  //     };

  //     if (user.isRegister === "2") {
  //       axios
  //         .post(verifyOtpUrl, val, { headers: headers })
  //         .then((res) => {
  //           if (res.data.status === true) {
  //             if (res.data.registration === 2) {
  //               const token = res.data.body.access_token;
  //               const userDetails = res.data.body.user;

  //               localStorage.setItem("userDetails", JSON.stringify(userDetails));
  //               localStorage.setItem("access_token", token);
  //               setloading(false);
  //               dispatch(
  //                 uiActions.isLoginModalOpen({
  //                   open: false,
  //                 })
  //               );
  //               dispatch(
  //                 uiActions.userIsLoggedIn({
  //                   loggedIn: true,
  //                 })
  //               );
  //               SweetAlert("success", t("alerts.user.login"));
  //             }
  //           } else if (res.data.status === false) {
  //             setloading(false);
  //             SweetAlert("error", res.data.message);
  //           }
  //         })
  //         .catch((err) => {
  //           setloading(false);
  //           console.log(err, "this is the error which we are  getting here");
  //         });
  //     } else if (user.isRegister === "1") {
  //       localStorage.setItem("otp", otp);
  //       dispatch(
  //         authAction.letCheckUserExist({
  //           isExist: true,
  //         })
  //       );
  //       dispatch(
  //         authAction.clickedOnLogin({
  //           clicked: false,
  //         })
  //       );
  //       setloading(false);
  //     }
  //   }
  // };
  return (
    <div className={styles.form}>
      <span onClick={closeOtpModal} className={styles.form__close}>
        <IoMdClose />
      </span>

      <div className={styles.form__info}>
        <span className={styles.form__info__title}>{t("otpScreen.title")}</span>
        <p className={styles.form__info__description}>{t("otpScreen.desc")}</p>
      </div>
      <div className={styles.form__inputs}>
        <input ref={firstInputRef} style={one.length === 1 ? style : unstyle} onChange={firstNum} type="num" maxLength="1" />
        <input ref={secondInputRef} style={two.length === 1 ? style : unstyle} onChange={secondNum} type="num" maxLength="1" />
        <input ref={thirdInputRef} style={three.length === 1 ? style : unstyle} onChange={thirdNum} type="num" maxLength="1" />
        <input ref={forthInputRef} style={four.length === 1 ? style : unstyle} onChange={forthNum} type="num" maxLength="1" />
      </div>
      <ButtonTwo onClick={verifyUserOtp} name={t("otpScreen.verify")} loading={loading} />
      <p className={styles.form__resend}>
        {t("otpScreen.bot")}
        <span className={styles.form__resend__resendAction}>{t("otpScreen.resend")}</span>
      </p>
    </div>
  );
};

export default OtpScreen;
