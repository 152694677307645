import React from "react";
import Styles from "./index.module.scss";

const ChildCat = ({ facilityList, getPackageListByChildCat }) => {
  return (
    <div className={Styles.serviceList}>
      <ul className={Styles.serviceList__subServices}>
        {facilityList?.map((item, index) => {
          return (
            <li key={index} className={Styles.serviceList__subServices__list}>
              <p className={Styles.serviceList__subServices__list__title}>{item.name}</p>
              <ul>
                {item.child_category.map((list, index) => {
                  return (
                    <li
                      key={index}
                      onClick={(e) =>
                        getPackageListByChildCat(e, list.id, item.name, list.name, list.subcategory_id, item.parent_id)
                      }>
                      {list.name}
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChildCat;
