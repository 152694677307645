import Lottie from "lottie-react";
import success from "../../assets/animation/order2.json";
import React from "react";
import Container from "../../shared/Container";
import styles from "./index.module.scss";
import ButtonTwo from "../../widgets/ButtonTwo";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const ThankYou = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const onNavigateToHome = () => {
    navigate("/");
  };
  return (
    <Container>
      <div className={styles.thankYou}>
        <div className={styles.thankYou__animCont}>
          <Lottie loop={true} animationData={success} className={styles.thankYou__animCont__animation} />
        </div>
        <h1>{t("thank.success")}</h1>
        <p>
          {t("thank.order")} {params.orderId}
        </p>
        <div className={styles.thankYou__btn}>
          <ButtonTwo name={t("home")} onClick={onNavigateToHome} />
        </div>
      </div>
    </Container>
  );
};

export default ThankYou;
