import React, { useEffect, useState } from "react";
import { endpoints } from "../../services/endpoints";
import NoOffers from "../../assets/offers/noOffers.png";
import styles from "./index.module.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import OfferCard from "../OfferCard";
import Container from "../../shared/Container";
import Carousel from "react-multi-carousel";
import Title from "../Title";

const OfferBanner = () => {
  const [offer, setOffer] = useState([]);

  const { i18n } = useTranslation();

  const fetchedOffer = async () => {
    try {
      const response = await axios.get(endpoints.home.bannerOffers);

      if (response.status !== 200) {
        throw new Error("Request failed with status " + response.status);
      }

      const data = response.data;
      setOffer(data.body);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    fetchedOffer();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2.5,
    },
    laptop: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Container>
      <div className={styles.offerBanner}>
        {/* <div className={styles.offerBanner__top}>
          <h1 className={styles.offerBanner__top__title}>{i18n.language === "ar" ? "عروض لك" : "Offers For You"}</h1>
        </div> */}
        {/* <Title title={i18n.language === "ar" ? "عروض لك" : "Offers For You"} /> */}
        <Title
          // title={i18n.language === "ar" ? "العروض" : ""}
          headline={i18n.language === "ar" ? "العروض لك" : "Offers For You"}
        />
        <div className={styles.offerBanner__bottom}>
          <Carousel
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
            partialVisible={false}>
            {offer?.length !== 0 &&
              offer?.map((item, index) => {
                return (
                  <OfferCard
                    img={item.image_id}
                    // heading={item.offer_name}
                    // price={item.amount}
                    // discount={item.discount_type}
                    key={index}
                    data={item}
                    allOffer={offer}
                  />
                );
              })}
          </Carousel>
          {offer?.length === 0 && <img src={NoOffers} alt="noOffer" />}
        </div>
      </div>
    </Container>
  );
};

export default OfferBanner;
