import React, { useEffect, useState } from "react";
import Container from "../../shared/Container";
import styles from "./index.module.scss";
import pastBookings from "../../assets/notData.svg";
import BookingCard from "../../components/BookingCard";
import { endpoints } from "../../services/endpoints";
import axios from "axios";
import Loader from "../../components/Loader";
import { generatePath, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PastBookings = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const locDet = JSON.parse(localStorage.getItem("locDet"));

  useEffect(() => {
    setLoading(true);
    if (token) {
      const url = endpoints.booking.past;

      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (res.data.status) {
            setLoading(false);
            setData(res.data.body);
          } else {
            setData([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  const onNavigateToProdDet = (itm) => {
    const path = generatePath("/:location/:masterCat/:masterId/:subId/:name/:prodId", {
      location: locDet.city,
      masterCat: itm.category_name,
      masterId: btoa(itm.subcategory_name),
      subId: btoa(itm.subcategory),
      name: itm.title.replaceAll(" ", "-"),
      prodId: btoa(itm.id),
    });
    navigate(path);
  };

  return (
    <>
      {/* <Categories /> */}
      <Container>
        <h1 className={styles.h1}>{t("booking.past1")}</h1>
        {loading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <>
            {data.length < 1 && (
              <div className={styles.wishlist}>
                <img src={pastBookings} alt="favourite" />
                <h1>{t("booking.noData")}</h1>
              </div>
            )}
            {data.length >= 1 && (
              <div className={styles.wishlist1}>
                {data.map((item, ind) => (
                  <BookingCard
                    key={`${item.id + ind}`}
                    title={item.title}
                    date={item.dateMonth}
                    img={item.image_id}
                    purchased={item.purchased_price}
                    reviews={item.reviews}
                    rating={item.rating}
                    screen="past"
                    onClick={() => onNavigateToProdDet(item)}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default PastBookings;
