import React from "react";
import styles from "./index.module.scss";

const Charges = () => {
  return (
    <div className={styles.charges}>
      <div className={styles.charges__left}>
        <ul className={styles.charges__left__ul}>
          <li className={styles.charges__left__ul__li}>
            <span>Sub Total: </span>
            <span className={styles.charges__left__ul__li__span}>₹ 2100.00</span>
          </li>
          <li className={styles.charges__left__ul__li}>
            <span>Discount: </span>
            <span className={styles.charges__left__ul__li__span}>₹ 00.00</span>
          </li>
          <li className={styles.charges__left__ul__li}>
            <span>CGST (10%): </span>
            <span className={styles.charges__left__ul__li__span}>₹ 180.00</span>
          </li>
          <li className={styles.charges__left__ul__li}>
            <span>SGST (10%): </span>
            <span className={styles.charges__left__ul__li__span}>₹ 180.00</span>
          </li>
        </ul>
        <div className={styles.charges__left__total}>
          <p>Total:</p>
          <p>₹ 2460.00</p>
        </div>
      </div>
    </div>
  );
};

export default Charges;
