import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import axios from "axios";
import { endpoints } from "../../services/endpoints";
import { useDispatch, useSelector } from "react-redux";
import CatCard from "../CatCard";
import Carousel from "react-multi-carousel";
import { uiActions } from "../../utils/reducers/ui";
import { generatePath, useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import Container from "../../shared/Container";
import { useTranslation } from "react-i18next";

const Categories = () => {
  const locationDet = JSON.parse(localStorage.getItem("locDet"));
  const loading = useSelector((state) => state.ui.isLoading);
  const location = useSelector((state) => state.ui.cityName);
  const [categoryList, setCategoryList] = useState([]);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getFilterCategoryList = () => {
    dispatch(
      uiActions.currentlyLoading({
        loading: true,
      })
    );

    const formdata = new FormData();
    formdata.append("location_id", locationDet.id);

    const getConfig = {
      url: endpoints.home.filterCategory,
      method: "post",
      data: formdata,
    };
    axios(getConfig)
      .then((res) => {
        if (res.data.status) {
          setCategoryList(res.data.body);
          dispatch(
            uiActions.currentlyLoading({
              loading: false,
            })
          );
        }
      })
      .catch((err) => {
        console.log("Error", err);
        dispatch(
          uiActions.currentlyLoading({
            loading: false,
          })
        );
      });
  };

  const getProductsByCategories = async (itm) => {
    dispatch(
      uiActions.skelatonLoadingCurrently({
        loading: true,
      })
    );
    let url = endpoints.deatils.getPackageParentCategory + itm.id;
    let response = await fetch(url);
    let data = await response.json();

    let products = data.body;
    let base64id = btoa(itm.id);

    let master = itm.name.replace(/Xperience[s]?/i, "").trim();
    const path = generatePath("/:location/:masterCat/:masterId", {
      location: locationDet.city,
      masterCat: master.replaceAll(" ", "-"),
      masterId: base64id,
    });

    if (!response.ok) {
      dispatch(
        uiActions.skelatonLoadingCurrently({
          loading: false,
        })
      );
      throw new Error("Something Went Wrong");
    }

    navigate(path, { state: { allProducts: products } });
    dispatch(
      uiActions.skelatonLoadingCurrently({
        loading: false,
      })
    );
  };

  useEffect(() => {
    if (locationDet?.id) {
      getFilterCategoryList();
    }
    // eslint-disable-next-line
  }, [location?.id]);

  const rtl = i18n.language === "ar" ? true : false;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
    },
    laptop: {
      breakpoint: { max: 1024, min: 768 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 768, min: 480 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 3,
    },
  };

  return (
    <Container>
      <div className={styles.categories}>
        {loading ? (
          <div className={styles.categories__body1}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
              return <Skeleton key={index} variant="rectangular" height={100} width={100} />;
            })}
          </div>
        ) : (
          <div className={styles.categories__body}>
            <Carousel
              rtl={rtl}
              responsive={responsive}
              autoPlay={true}
              swipeable={true}
              draggable={true}
              customTransition="all .5s"
              transitionDuration={500}
              autoPlaySpeed={2000}
              showDots={false}
              infinite={true}
              partialVisible={false}>
              {categoryList.map((item) => {
                return <CatCard key={item.id} item={item} getProductsByCategories={getProductsByCategories} />;
              })}
            </Carousel>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Categories;
