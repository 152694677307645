import React from "react";
import styles from "./index.module.scss";
import Title from "../Title";
import { useTranslation } from "react-i18next";

const Occassion = ({ onAboutPlat }) => {
  const { t, i18n } = useTranslation();
  const occassion = [
    { id: 1, nameEn: "Anniversary", nameAr: "ذكرى الزواج" },
    { id: 2, nameEn: "Baby Shower", nameAr: "استحمام الطفل" },
    { id: 3, nameEn: "First Birthday", nameAr: "اول عيد ميلاد" },
    { id: 4, nameEn: "Bachelorette", nameAr: "العازبة" },
    { id: 5, nameEn: "Festival", nameAr: "مهرجان" },
    { id: 6, nameEn: "Partner's Birthday", nameAr: "عيد ميلاد الشريك" },
    { id: 7, nameEn: "Casual Outing", nameAr: "نزهة عادية" },
    { id: 8, nameEn: "For my Parents", nameAr: "لوالدي" },
    { id: 9, nameEn: "For my Kids", nameAr: "لأطفالي" },
    { id: 10, nameEn: "For Brother or Sister", nameAr: "للأخ أو الأخت" },
    { id: 11, nameEn: "Valentine's", nameAr: "عيد الحب" },
    { id: 12, nameEn: "Karvachauth", nameAr: "كارفاشوث" },
    { id: 13, nameEn: "Welcome Baby", nameAr: "اهلا عزيزي" },
    { id: 14, nameEn: "House Warming", nameAr: "حفلة متحركة" },
    { id: 15, nameEn: "Shop Inauguration", nameAr: "افتتاح المحل" },
    { id: 16, nameEn: "Office", nameAr: "مكتب" },
    { id: 17, nameEn: "Other", nameAr: "خيار آخر" },
  ];

  return (
    <div className={styles.knowAbout}>
      <Title title={t("checkout.occassion")} />
      <ul className={styles.knowAbout__ul}>
        {occassion.map((item) => {
          return (
            <li onClick={() => onAboutPlat(item)} className={styles.knowAbout__ul__li} key={item.id}>
              <input type="radio" name="timeslot" />
              <p>{i18n.language === "ar" ? item.nameAr : item.nameEn}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Occassion;
