import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useParams } from "react-router-dom";
import Categories from "../../components/Categories";
import Testimonial from "../../components/Testimonial";
import WhyChooseUs from "../../components/WhyChooseUs";
import Card from "../../components/Card";
import OfferBanner from "../../components/OfferBanner";
import { uiActions } from "../../utils/reducers/ui";
import { useDispatch, useSelector } from "react-redux";
// import Container from "../Container";
import { endpoints } from "../../services/endpoints";
import axios from "axios";
import { Skeleton } from "@mui/material";
import Container from "../../shared/Container";
import Angle from "../../widgets/Angle";
import { useTranslation } from "react-i18next";

const AllCategoriesProductList = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [allProducts, setAllProducts] = useState([]);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui.skelatonLoading);
  const isFav = useSelector((state) => state.products.isFavrtExist);
  let decodeMasterId = atob(params.masterId);

  useEffect(() => {
    const url = endpoints.deatils.getPackageParentCategory + decodeMasterId;
    dispatch(
      uiActions.skelatonLoadingCurrently({
        loading: true,
      })
    );
    axios
      .get(url)
      .then((res) => {
        if (res.data.status === true) {
          const val = res.data.body;
          setAllProducts(val);
          dispatch(
            uiActions.skelatonLoadingCurrently({
              loading: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          uiActions.skelatonLoadingCurrently({
            loading: false,
          })
        );
        console.log(err, "Category Api All Packages Failed");
      });

    // eslint-disable-next-line
  }, [decodeMasterId, isFav]);
  return (
    <>
      <Categories />
      <Container>
        <div className={styles.allList}>
          <h1 className={styles.allList__title}>
            {t("home")} <Angle />
            {params.masterCat
              .replace(/Xperience[s]?/i, "")
              .trim()
              .replaceAll("-", " ")}
          </h1>

          <div className={styles.allList__body}>
            {loading
              ? [1, 2, 3, 4].map((item) => {
                  return <Skeleton key={item} variant="rectangular" height={350} style={{ borderRadius: "10px" }} />;
                })
              : allProducts.map((item) => {
                  return (
                    <Card
                      id={item.id}
                      key={item.id}
                      title={item.title}
                      src={item.image_id}
                      price={item.discounted_price}
                      original_price={item.outlay_price}
                      discount={item.discount_percnt}
                      masterId={decodeMasterId}
                      subId={item.subcat_id}
                      data={item}
                    />
                  );
                })}
          </div>
        </div>
      </Container>
      <div style={{ marginBottom: "30px" }}>
        <OfferBanner />
      </div>
      <Testimonial />
      <WhyChooseUs />
    </>
  );
};

export default AllCategoriesProductList;
