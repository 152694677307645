import React from "react";
import styles from "./index.module.scss";
import Button from "../../widgets/Button";
import { FaStar } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const BookingCard = ({ screen, title, date, img, purchased, rating, reviews, onClick, onClick2 }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.bookingCard}>
      <div className={styles.bookingCard__left}>
        <img src={img} alt="package" />
      </div>
      <div className={styles.bookingCard__right}>
        <h1>{title}</h1>
        <div className={styles.bookingCard__right__childs}>
          <div className={styles.bookingCard__right__childs__rating}>
            <p>{rating}</p>
            <FaStar className={styles.bookingCard__right__childs__rating__icon} />
          </div>
          <p className={styles.bookingCard__right__childs__review}>
            {reviews} {t("details.reviews")}
          </p>
        </div>
        {/* <p>Vikas Nagar, Kamla Nehru Nagar, Lucknow, Uttar Pradesh, 226022</p> */}
        <h2>₹ {purchased}</h2>
        <div className={styles.bookingCard__right__childs1}>
          <p className={styles.bookingCard__right__childs1__timedate}>{date}</p>
          <div className={styles.bookingCard__right__childs1__btn}>
            {screen === "past" ? (
              <Button onClick={onClick} name={t("booking.book")} />
            ) : (
              <Button onClick={onClick} name={t("booking.details")} background="gray" border="1px solid gray" />
            )}
          </div>
        </div>
        {screen === "upcoming" && (
          <Button onClick={onClick2} name={t("booking.cancel")} background="#fe6684" border="1px solid #fe6684" />
        )}
      </div>
    </div>
  );
};

export default BookingCard;
