import React from "react";
import styles from "./index.module.scss";
import ProgressLoader from "../../shared/ProgressLoader";

const Button = ({ name, background, color, onClick, loading, border }) => {
  return (
    <button
      onClick={onClick}
      style={{ background: `${background}`, color: `${color}`, border: `${border}` }}
      className={styles.btn}>
      {loading ? <ProgressLoader /> : name}
    </button>
  );
};

export default Button;
