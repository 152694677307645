import React, { useLayoutEffect, useState } from "react";
import styles from "./index.module.scss";
import { FaXmark } from "react-icons/fa6";
import axios from "axios";
import { endpoints } from "../../services/endpoints";
import LocationCard from "../../components/LocationCard";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { uiActions } from "../../utils/reducers/ui";

const LocationPicker = (props) => {
  const dispatch = useDispatch();
  const location = JSON.parse(localStorage.getItem("locDet"));
  const { t, i18n } = useTranslation();
  const [allLocation, setAllLocation] = useState([]);
  const [animate, setAnimate] = useState(false);
  const [loading, setLoading] = useState(false);
  useLayoutEffect(() => {
    setLoading(true);
    axios
      .get(endpoints.home.location)
      .then((res) => {
        if (res.data.status) {
          setAllLocation(res.data.body);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  const addAnimationClass = animate ? styles.closeModal : styles.openModal;
  const closeLocationModal = () => {
    setAnimate(true);
    dispatch(
      uiActions.openLocationModal({
        isTrue: false,
      })
    );
  };
  return (
    <div className={`${styles.locationPicker} ${animate && styles.closeModal}`}>
      <div className={`${i18n.language === "ar" ? styles.locationPicker__content : styles.locationPicker__body} ${addAnimationClass}`}>
        <div className={`${i18n.language === "ar" ? styles.locationPicker__content__top1 : styles.locationPicker__body__top}`}>
          <h1>{t("chooseCity")}</h1>
          {location && <FaXmark onClick={closeLocationModal} className={styles.locationPicker__body__top__icon} />}
        </div>
        <p className={styles.locationPicker__body__para}>{t("findMore")}</p>
        {loading ? (
          <div className={styles.locationPicker__body__loader}>
            <Loader />
          </div>
        ) : (
          <div className={styles.locationPicker__body__bottom}>
            {allLocation.map((location) => {
              return (
                <div
                  onClick={() => props.onClick(location.name, location.id)}
                  className={styles.locationPicker__body__bottom__card}
                  key={location.id}>
                  <LocationCard img={location.image_id} title={location.name} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationPicker;
