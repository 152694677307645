import React from "react";
import Input from "../../../widgets/Input";
import styles from "./index.module.scss";
import Title from "../Title";
import { useTranslation } from "react-i18next";

const OrderDetails = ({ onGetUserDetails, userdata }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.orderDetails}>
      <Title title={t("checkout.addA")} />
      <div className={styles.orderDetails__input}>
        <Input
          type="text"
          id="name"
          label={t("placeholder.name")}
          onChange={(e) => onGetUserDetails(e, "name")}
          value={userdata.name}
        />
        <Input
          type="number"
          id="phone"
          label={t("placeholder.number")}
          onChange={(e) => onGetUserDetails(e, "phone")}
          value={userdata.phone}
        />
      </div>
      <div className={styles.orderDetails__input}>
        <Input type="text" id="pincode" label={t("checkout.pincode")} value={userdata.pincode} disabled={true} />
        <Input
          type="text"
          id="locality"
          label={t("checkout.locality")}
          onChange={(e) => onGetUserDetails(e, "locality")}
          value={userdata.locality}
        />
      </div>
      <Input
        type="text"
        id="address"
        label={t("checkout.address")}
        onChange={(e) => onGetUserDetails(e, "address")}
        value={userdata.address}
      />
      <div className={styles.orderDetails__input}>
        <Input type="text" id="city" label={t("checkout.cityDT")} value={userdata.city} disabled={true} />
        <Input type="text" id="state" label={t("checkout.state")} value={userdata.state} disabled={true} />
      </div>
      <div className={styles.orderDetails__input}>
        <Input
          type="text"
          id="landmark"
          label={t("checkout.landmark")}
          onChange={(e) => onGetUserDetails(e, "landmark")}
          value={userdata.landmark}
        />
        <Input
          type="number"
          id="alternatePhone"
          label={t("checkout.alternatePhone")}
          onChange={(e) => onGetUserDetails(e, "alternatePhone")}
          value={userdata.alternatePhone}
        />
      </div>
      <textarea
        id="notes"
        value={userdata.message}
        onChange={(e) => onGetUserDetails(e, "notes")}
        className={styles.orderDetails__textarea}
        placeholder={t("placeholder.message")}
      />
    </div>
  );
};

export default OrderDetails;
