import React, { useState } from "react";
import styles from "./index.module.scss";
import SeeAll from "../SeeAll";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
// import axios from "axios";
// import { endpoints } from "../../services/endpoints";
import Card from "./Card";
import { tours } from "../../services/objects";
import Title from "../Title";
import Container from "../../shared/Container";
import TourForm from "../../modals/TourForm";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../utils/reducers/ui";

const Tour = () => {
  // const [tour, setTour] = useState([]);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [tourOtherDet, setTourOtherDet] = useState({});

  const isTour = useSelector((state) => state.ui.isTour);
  // const updatedLocation = JSON.parse(localStorage.getItem("locDet"));
  // const cityId = updatedLocation && updatedLocation.id;

  // useEffect(() => {
  //   const url = endpoints.home.tour;
  //   // const headers = {
  //   //   "Content-Type": "application/json; charset=utf-8",
  //   // };
  //   axios
  //     .get(url)
  //     .then((res) => {
  //       if (res.data.status) {
  //         setTour(res.data.body);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, [cityId]);

  const rtl = i18n.language === "ar" ? true : false;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    laptop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      partialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 768, min: 480 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      partialVisibilityGutter: 70,
      items: 1,
    },
  };

  const onTourForm = (detail) => {
    setTourOtherDet(detail);
    dispatch(
      uiActions.isTourFormOpen({
        isTrue: true,
      })
    );
  };

  return (
    <>
      {isTour && <TourForm data={tourOtherDet} />}
      <div className={styles.packages}>
        <Container>
          <div className={styles.packages__top}>
            <Title
              headline={i18n.language === "ar" ? "ملكنا Xperience جولات" : "Our Xperience Tour"}
              // title={i18n.language === "ar" ? "ملكنا" : "Our"}
            />
            <SeeAll />
          </div>
        </Container>
        <div className={styles.packages__container}>
          <Carousel
            rtl={rtl}
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            customTransition="all .5s"
            transitionDuration={500}
            autoPlaySpeed={2000}
            showDots={false}
            infinite={true}
            partialVisible={true}>
            {tours.map((item) => {
              return (
                <Card
                  key={item.id}
                  italic={i18n.language === "ar" ? item.rtlName : item.name}
                  bold={i18n.language === "ar" ? item.rtlTitle : item.title}
                  src={item.image_path}
                  onTourForm={() => onTourForm(item)}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Tour;
