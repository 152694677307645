import React from "react";
import styles from "./index.module.scss";
import Title from "../Title";

const CancellationPolicy = ({ cancellationPolicy, title }) => {
  return (
    <div className={styles.cancellation}>
      <Title title={title} />
      <div className={styles.cancellation__content} dangerouslySetInnerHTML={{ __html: cancellationPolicy }}></div>
    </div>
  );
};

export default CancellationPolicy;
