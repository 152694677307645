import React, { useState } from "react";
import styles from "./index.module.scss";
import Container from "../../shared/Container";
import UpcomingBookings from "../UpcomingBookings";
import PastBookings from "../PastBookings";
import { useTranslation } from "react-i18next";

const Bookings = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState("upcoming");
  const bookingTabs = (name) => {
    setActive(name);
  };
  return (
    <Container>
      <div className={styles.bookings}>
        <div className={styles.bookings__tab}>
          <ul>
            <li className={active === "upcoming" ? styles.active : ""} onClick={() => bookingTabs("upcoming")}>
              {t("booking.upcoming")}
            </li>
            <li className={active === "past" ? styles.active : ""} onClick={() => bookingTabs("past")}>
              {t("booking.past")}
            </li>
          </ul>
        </div>
        {active === "upcoming" && <UpcomingBookings />}
        {active === "past" && <PastBookings />}
      </div>
    </Container>
  );
};

export default Bookings;
