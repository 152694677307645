import React from "react";
import styles from "./index.module.scss";
import { FaQuoteRight } from "react-icons/fa";
import flow from "../../../assets/testimonial/pinkflow.svg";

const Card = (props) => {
  return (
    <div className={styles.card}>
      <FaQuoteRight className={styles.card__quotes} />
      <h2 className={styles.card__title}>{props.message}</h2>
      <div className={styles.card__bottom}>
        <div className={styles.card__bottom__user}>
          <img src={props.user} alt="user" />
        </div>
        <div className={styles.card__bottom__ocean}>
          <img src={flow} alt="flow" />
        </div>
      </div>
    </div>
  );
};

export default Card;
