import React from "react";
import styles from "./index.module.scss";
import { FaPlus, FaMinus } from "react-icons/fa";
import ToggleSwitch from "../../widgets/ToggleSwitch";

const CustomizeCard = ({ img, title, desc, price, all, counter, onAddCustomize, onIncrement, onDecrement }) => {
  return (
    <div className={styles.customizeCard}>
      <div className={styles.customizeCard__imgCont}>
        <img src={img} alt="packages" />
      </div>
      <div className={styles.customizeCard__details}>
        <div className={styles.customizeCard__details__left}>
          <h3>{title}</h3>
          <p>{desc}</p>
          <p>Rs. {price}</p>
        </div>
        <div className={styles.customizeCard__details__content}>
          {all.singlepiece ? (
            <ToggleSwitch id={all.id} onChange={(e) => onAddCustomize(e, all)} />
          ) : (
            <div className={styles.customizeCard__details__content__counter}>
              <p
                onClick={counter === 0 ? () => {} : () => onDecrement(all)}
                style={{ backgroundColor: counter === 0 ? "#707070" : "#ffab02" }}
                className={styles.customizeCard__details__content__counter__buttons}>
                <FaMinus color="#fff" fontSize={16} />
              </p>
              <p className={styles.customizeCard__details__content__counter__buttons1}>{counter}</p>
              <p
                onClick={counter === all.quantity ? () => {} : () => onIncrement(all)}
                style={{ backgroundColor: counter === all.quantity ? "#707070" : "#ffab02" }}
                className={styles.customizeCard__details__content__counter__buttons}>
                <FaPlus color="#fff" fontSize={16} />
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomizeCard;
