import React from "react";
import styles from "./index.module.scss";

const Input2 = (props) => {
  return (
    <div className={styles.input2}>
      <p>{props.label}</p>
      <input
        onChange={(e) => props.onChange(e, props.id)}
        id={props.id}
        type={props.type}
        placeholder={props.label}
        value={props.value}
        min={props.min}
        onInput={props.onKeyUp}
        disabled={props.disabled}
        max={props.max}
      />
    </div>
  );
};

export default Input2;
