import React, { useState } from "react";
import Modal from "..";
import Button from "../../widgets/Button";
import styles from "./index.module.scss";
import Close from "../../components/Close";
import Loader from "../../components/Loader";
import Input from "../../widgets/Input";
import { useTranslation } from "react-i18next";

const CancelOrder = ({ items, closeDetailModal, loading, onCancelBookingItem }) => {
  const { t } = useTranslation();
  const [selectedReason, setSelectedReason] = useState("");
  const [active, setActive] = useState("bank");

  const onClickedReason = (itm) => {
    setSelectedReason(itm);
  };

  const onActiveTab = (tb) => {
    setActive(tb);
  };

  const stylesForInput = {
    marginBottom: 0,
  };

  return (
    <Modal>
      <div className={styles.details}>
        <h1>{t("cancel.cancelR")}</h1>
        <Close onClick={closeDetailModal} />
        {loading ? (
          <div className={styles.details__loader}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={styles.details__refund}>
              <div className={styles.details__refund__tab}>
                <ul>
                  <li onClick={() => onActiveTab("bank")} className={`${active === "bank" && styles.active}`}>
                    {t("cancel.bank")}
                  </li>
                  <li onClick={() => onActiveTab("upi")} className={`${active === "upi" && styles.active}`}>
                    {t("cancel.upi")}
                  </li>
                </ul>
              </div>
              {active === "bank" && (
                <div className={styles.details__bank}>
                  <Input styleCont={stylesForInput} label={t("tPortal.fullN")} />
                  <Input styleCont={stylesForInput} label={t("cancel.aNo")} />
                  <Input styleCont={stylesForInput} label={t("cancel.ifsc")} />
                  <Input styleCont={stylesForInput} label={t("cancel.bName")} />
                </div>
              )}
              {active === "upi" && (
                <div className={styles.details__bank2}>
                  <Input styleCont={stylesForInput} label={t("cancel.uId")} />
                </div>
              )}
            </div>
            {items.map((item, ind) => {
              const isSelected = selectedReason.trim() === item.trim();
              return (
                <p key={ind} onClick={() => onClickedReason(item)}>
                  <input type="radio" name="reason" checked={isSelected} onChange={() => {}} />
                  <span>{item}</span>
                </p>
              );
            })}
            <p onClick={() => onClickedReason("Others")}>
              <input type="radio" name="reason" checked={selectedReason === "Others"} onChange={() => {}} />
              <span>{t("cancel.others")}</span>
            </p>

            {selectedReason === "Others" && <Input label={t("cancel.writeYR")} />}

            <Button name={t("cancel.yCancel")} onClick={onCancelBookingItem} />
          </>
        )}
      </div>
    </Modal>
  );
};

export default CancelOrder;
