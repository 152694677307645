import React from "react";
import styles from "./index.module.scss";
import { FaCalendar } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import Button from "../../widgets/Button";
import { generatePath, useNavigate } from "react-router-dom/dist";

const BlogCard = ({ src, title, date, msg, data }) => {
  const navigate = useNavigate();

  const onNavigateToDetails = () => {
    const path = generatePath("/blog-details/:title", {
      title: title.replaceAll(" ", "-"),
    });

    navigate(path, { state: { details: data } });
  };

  return (
    <div className={styles.blogCard}>
      <div className={styles.blogCard__img}>
        <img src={src} alt={title} />
      </div>
      <div className={styles.blogCard__date}>
        <div className={styles.blogCard__date__child}>
          <FaCalendar className={styles.blogCard__date__child__icon} /> <p>{date}</p>
        </div>
        <div className={styles.blogCard__date__child}>
          <FaMessage className={styles.blogCard__date__child__icon} /> <p>LEAVE A COMMENT</p>
        </div>
      </div>
      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: msg }}></p>

      <div className={styles.blogCard__btn}>
        <Button onClick={onNavigateToDetails} name="Read More" />
      </div>
    </div>
  );
};

export default BlogCard;
