import React, { useEffect, useState } from "react";
// import Loader from "../../components/Loader";
import HomeBanner from "../../components/HomeBanner";
import candle from "../../assets/BestSeller/candlelightdinner.webp";
import party from "../../assets/BestSeller/partydecoration.webp";
import balloon from "../../assets/BestSeller/balloondecoration.webp";
import { useDispatch } from "react-redux";
import Categories from "../../components/Categories";
import BestSellerZone from "../../components/BestSellerZone";
import { endpoints } from "../../services/endpoints";
import axios from "axios";
import Packages from "../../components/Packages";
import OfferBanner from "../../components/OfferBanner";
import Tour from "../../components/Tour";
import Testimonial from "../../components/Testimonial";
import WhyChooseUs from "../../components/WhyChooseUs";
import { useTranslation } from "react-i18next";
import { uiActions } from "../../utils/reducers/ui";

const Home = () => {
  const dispatch = useDispatch();
  const [showList, setShowList] = useState([]);
  const [loading, setLoading] = useState(true);
  const updatedLocation = JSON.parse(localStorage.getItem("locDet"));
  const { t } = useTranslation();
  const bestSellers = [
    { id: 1, name: "Candle Night Dinner", image: candle, rtlName: "عشاء ليلة شمعة" },
    { id: 2, name: "Party Decorations", image: party, rtlName: "زينة الحفلات" },
    { id: 3, name: "Balloon Decorations", image: balloon, rtlName: "زينة البالون" },
  ];

  const cityId = updatedLocation && updatedLocation.id;

  useEffect(() => {
    setLoading(true);
    const url = endpoints.home.homeScreen;
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
    };

    axios
      .post(url, { location_id: cityId, headers })
      .then((res) => {
        if (res.data.status) {
          setShowList(res.data.body);
          setLoading(false);
          dispatch(
            uiActions.skelatonLoadingCurrently({
              loading: false,
            })
          );
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [cityId]);

  return (
    <>
      <HomeBanner />
      <Categories />
      <BestSellerZone items={bestSellers} />
      {showList[0]?.content?.length > 0 && (
        <Packages loading={loading} items={showList[0].content} heading={t("package.exciting")} />
      )}
      <OfferBanner />
      {showList[1]?.content?.length > 0 && (
        <Packages loading={loading} items={showList[2].content} heading={t("package.experience")} />
      )}
      <Tour />
      <Testimonial />
      <WhyChooseUs />
    </>
  );
};

export default Home;
