import React from "react";
import styles from "./index.module.scss";

const Table = () => {
  return (
    <table className={styles.table}>
      <tbody className={styles.table__tbody}>
        <tr className={styles.table__tbody__tr}>
          <th className={styles.table__tbody__tr__box1}>S.No.</th>
          <th className={styles.table__tbody__tr__box}>Items</th>
          <th className={styles.table__tbody__tr__box3}>Quantity</th>
          <th className={styles.table__tbody__tr__box3}>Rate</th>
          <th className={styles.table__tbody__tr__box2}>Discount/Coupon</th>
          <th className={styles.table__tbody__tr__box3}>Amount</th>
        </tr>

        <tr className={styles.table__tbody__tr}>
          <td className={styles.table__tbody__tr__data1}>01</td>
          <td className={styles.table__tbody__tr__data}>Items</td>
          <td className={styles.table__tbody__tr__data3}>Quantity</td>
          <td className={styles.table__tbody__tr__data3}>Rate</td>
          <td className={styles.table__tbody__tr__data2}>Discount/Coupon</td>
          <td className={styles.table__tbody__tr__data3}>Amount</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Table;
