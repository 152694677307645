import React, { useState } from "react";
import Modal from "..";
import tour from "../../assets/animation/tour.json";
import Lottie from "lottie-react";
import styles from "./index.module.scss";
import Button from "../../widgets/Button";
import Input2 from "../../widgets/Input2";
import { uiActions } from "../../utils/reducers/ui";
import { useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import SweetAlert from "../../shared/SweetAlert";
import { submitEnquiryForm } from "../../services/server";
import { useTranslation } from "react-i18next";

const TourForm = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tourDetails, setTourDetails] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
    trip: data?.name,
    place: "Saudi Arabia",
    destination: data?.title,
    travelDate: "",
    planningDate: "",
    days: "",
  });

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(
      uiActions.isTourFormOpen({
        isTrue: false,
      })
    );
  };

  const onTourDetails = (e, id) => {
    setTourDetails((prevState) => ({ ...prevState, [id]: e.target.value }));
  };

  function validateEmail(email) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }

  const onSubmitTourForm = async () => {
    if (tourDetails.name === "") {
      SweetAlert("warning", "Please Enter Your Full Name");
    } else if (tourDetails.name.trim().length <= 3) {
      SweetAlert("warning", "Length Should Be Greater Than 3");
    } else if (!validateEmail(tourDetails.email)) {
      SweetAlert("warning", "Enter A Valid Email");
    } else if (tourDetails.number.trim().length !== 10) {
      SweetAlert("warning", "Please Enter A Valid Mobile Number!");
    } else if (tourDetails.message.trim().length <= 3) {
      SweetAlert("warning", "Please Elobrate Something About Your Tour!");
    } else if (tourDetails.days < 1) {
      SweetAlert("warning", "Enter Valid Days");
    } else {
      let datas = {
        name: tourDetails.name,
        email: tourDetails.email,
        phone: tourDetails.number,
        note: tourDetails.message,
        object_id: data.id,
        object_model: "package",
        additional_info: {
          trip_plan: tourDetails.trip,
          destination_plan: tourDetails.destination,
          destination_place: tourDetails.place,
          travel_date: tourDetails.travelDate,
          planning_date: tourDetails.planningDate,
          staying_days: tourDetails.days,
        },
      };
      setLoading(true);
      const res = await submitEnquiryForm(datas);

      if (res.status) {
        setLoading(false);
        dispatch(
          uiActions.isTourFormOpen({
            isTrue: false,
          })
        );
        SweetAlert("success", res.message);
      } else {
        setLoading(false);
      }
    }
  };

  const date = new Date();
  let month = date.getMonth() + 1;
  let currDate = `${date.getFullYear()}-${month > 9 ? month : "0" + month}-${date.getDate()}`;

  return (
    <Modal onClose={onClose} width="70%">
      <div className={styles.tourForm}>
        <span
          style={{ right: `${i18n.language === "ar" ? "" : "10px"}`, left: `${i18n.language === "ar" ? "10px" : ""}` }}
          onClick={onClose}
          className={styles.tourForm__close}>
          <IoMdClose />
        </span>
        <div className={styles.tourForm__left}>
          <h2>{t("tPortal.title")}</h2>
          <p>{t("tPortal.para")}</p>
          <div className={styles.tourForm__left__anim}>
            <Lottie loop={true} animationData={tour} className={styles.tourForm__left__anim__animation} />
          </div>
        </div>
        <div className={styles.tourForm__right}>
          <h2 style={{ marginTop: 0 }}>{t("tPortal.enquiryT")}</h2>
          <div className={styles.tourForm__right__top}>
            <Input2 onChange={onTourDetails} value={tourDetails.name} label={t("tPortal.fullN")} id="name" type="text" />
            <Input2 onChange={onTourDetails} value={tourDetails.email} label={t("placeholder.email")} id="email" type="email" />
            <Input2
              onChange={onTourDetails}
              value={tourDetails.number}
              label={t("placeholder.number")}
              id="number"
              type="number"
            />
          </div>
          <p>{t("placeholder.message")}</p>
          <textarea
            onChange={(e) => onTourDetails(e, "message")}
            id="message"
            className={styles.tourForm__right__textarea}
            placeholder={t("placeholder.message")}
          />
          <h2>{t("tPortal.addI")}</h2>
          <div className={styles.tourForm__right__additional}>
            <Input2 onChange={onTourDetails} value={tourDetails.trip} label={t("tPortal.tripP")} id="trip" type="text" />
            <Input2
              onChange={onTourDetails}
              value={tourDetails.destination}
              label={t("tPortal.destP")}
              id="destination"
              type="text"
            />
            <Input2 onChange={onTourDetails} value={tourDetails.place} label={t("tPortal.destPlace")} id="place" type="text" />
            <Input2
              onChange={onTourDetails}
              value={tourDetails.planningDate}
              label={t("tPortal.planD")}
              id="planningDate"
              type="date"
              min={currDate}
            />
            <Input2
              onChange={onTourDetails}
              min={tourDetails.planningDate}
              value={tourDetails.travelDate}
              label={t("tPortal.travelD")}
              id="travelDate"
              type="date"
              disabled={tourDetails.planningDate === ""}
            />
            <Input2
              onChange={onTourDetails}
              value={tourDetails.days}
              label={t("tPortal.stayD")}
              id="days"
              type="number"
              min="1"
            />
          </div>
          <div className={styles.tourForm__right__btn}>
            <Button onClick={onSubmitTourForm} name={t("tPortal.submit")} loading={loading} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TourForm;
