import React from "react";
import styles from "./index.module.scss";

const ProgressLoader = ({ color }) => {
  return (
    <div className={styles.loader}>
      <div style={{ backgroundColor: `${color}` }} className={styles.loader__box}></div>
      <div style={{ backgroundColor: `${color}` }} className={styles.loader__box}></div>
      <div style={{ backgroundColor: `${color}` }} className={styles.loader__box}></div>
      <div style={{ backgroundColor: `${color}` }} className={styles.loader__box}></div>
    </div>
  );
};

export default ProgressLoader;
