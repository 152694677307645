import React from "react";
import styles from "./index.module.scss";

const SmallViews = ({ src, onClick, className }) => {
  return (
    <div onClick={onClick} className={`${styles.smallViews} ${className}`}>
      <img src={src} alt={src} />
    </div>
  );
};

export default SmallViews;
