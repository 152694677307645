import React, { useEffect } from "react";
import styles from "./index.module.scss";
import Container from "../../shared/Container";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { FaCalendar } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";

const BlogDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const blogDet = state?.details;

  useEffect(() => {
    if (state.details === undefined) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className={styles.blogDetails}>
        <h2>{blogDet.title}</h2>
        <div className={styles.blogDetails__img}>
          <img src={blogDet.image_id} alt={blogDet.title} />
        </div>
        <div className={styles.blogDetails__date}>
          <div className={styles.blogDetails__date__child}>
            <FaCalendar className={styles.blogDetails__date__child__icon} /> <p>{blogDet.date}</p>
          </div>
          <div className={styles.blogDetails__date__child}>
            <FaMessage className={styles.blogDetails__date__child__icon} /> <p>LEAVE A COMMENT</p>
          </div>
        </div>

        <div className={styles.blogDetails__bottom}>
          {blogDet?.blog_part?.map((item) => {
            return (
              <div key={item.title}>
                <h2>{item.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default BlogDetails;
