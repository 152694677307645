import React from "react";
import Title from "../Title";
import styles from "./index.module.scss";

const Faqs = ({ faqs, title }) => {
  return (
    <div className={styles.faqs}>
      <Title title={title} />
      {faqs?.map((faq, index) => {
        return (
          <div className={styles.faqs__content} key={index}>
            <div className={styles.faqs__content__que}>
              <h3 className={styles.faqs__content__que__title}>Q.</h3>
              <h3>{faq.title}</h3>
            </div>
            <div className={styles.faqs__content__ans}>
              <p className={styles.faqs__content__ans__title}>A.</p>
              <p className={styles.faqs__content__ans__ans}>{faq.content}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Faqs;
