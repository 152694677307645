import React from "react";
import styles from "./index.module.scss";
import LogComp from "./LogComp";
import OtpScreen from "../OTP";
import { useSelector } from "react-redux";

const Login = ({ onUserLogin, loading, verifyUserOtp, otpLoading }) => {
  let loginClicked = useSelector((state) => state.auth.isClickedOnLogin);
  // const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);

  // const onUserLogin = (mob) => {
  //   setLoading(true);
  //   const url = endpoints.authentication.otpLogin;
  //   if (mob === "") {
  //     setLoading(false);
  //     SweetAlert("error", t("alerts.phone.one"));
  //   } else if (mob.length !== 10) {
  //     setLoading(false);
  //     SweetAlert("error", t("alerts.phone.two"));
  //   } else {
  //     const val = {
  //       phone: `91${mob}`,
  //     };

  //     const headers = {
  //       "Content-type": "application/json; charset=UTF-8",
  //     };

  //     axios
  //       .post(url, val, { headers: headers })
  //       .then((res) => {
  //         if (res.data.status === true) {
  //           setLoading(false);
  //           const register = res.data?.is_user_register;
  //           const resgisterDet = {
  //             isRegister: register,
  //             mob: mob,
  //           };

  //           localStorage.setItem("user", JSON.stringify(resgisterDet));

  //           dispatch(
  //             authAction.clickedOnLogin({
  //               clicked: true,
  //             })
  //           );

  //           SweetAlert("success", t("alerts.otp.one"));
  //         } else if (res.data.status === false) {
  //           SweetAlert("error", t("alerts.otp.two"));
  //         }
  //       })
  //       .catch((err) => {
  //         SweetAlert("error", t("alerts.otp.three"));
  //         console.log(err, "Otp Login Api Failed");
  //       });
  //   }
  // };
  return (
    <div className={styles.loginCont}>
      {loginClicked ? (
        <OtpScreen loading={otpLoading} verifyUserGivenOtp={verifyUserOtp} />
      ) : (
        <LogComp onUserLogin={onUserLogin} loading={loading} />
      )}
    </div>
  );
};

export default Login;
