import React from "react";
import styles from "./index.module.scss";

const ChooseCard = ({ src, title }) => {
  return (
    <div className={styles.chooseCard}>
      <div className={styles.chooseCard__img}>
        <img src={src} alt={title} />
      </div>
      <p className={styles.chooseCard__title}>{title}</p>
    </div>
    //  <div className={styles.chooseCard}>
    //   <div className={styles.chooseCard__cardInner}>
    //     <div className={styles.chooseCard__cardInner__img}>
    //       <img src={src} alt={title} />
    //     </div>
    //     <p className={styles.chooseCard__cardInner__title}>{title}</p>
    //   </div>
    // </div>
  );
};

export default ChooseCard;
