import React from "react";
import img from "../../../../assets/user.png";
import styles from "./index.module.scss";
import { FaStar } from "react-icons/fa";

const ReviewCard = ({ data }) => {
  return (
    <div className={styles.reviewCard}>
      <div className={styles.reviewCard__img}>
        <img src={data.icon_image ? data.icon_image : img} alt="user" />
      </div>
      <div className={styles.reviewCard__content}>
        <p>
          {data.rating} <FaStar color="#ffd700" />
        </p>
        <p>{data.description}</p>
      </div>
    </div>
  );
};

export default ReviewCard;
