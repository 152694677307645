import axios from "axios";
import { endpoints } from "./endpoints";

const access_token = localStorage.getItem("access_token");

const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${access_token}`);

export const getFilterList = (params) => {
  const getConfig = {
    url: endpoints.home.filterCategory,
    method: "post",
    data: params,
  };
  return axios(getConfig)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const getFavouritesList = () => {
  const getConfig = {
    url: endpoints.wishlist.allWishtList,
    method: "post",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  return axios(getConfig)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const getVendorsType = () => {
  const getConfig = {
    url: endpoints.marriage.vendorType,
    method: "get",
  };
  return axios(getConfig)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const submitMarriageData = (params) => {
  const getConfig = {
    url: endpoints.marriage.bookMarriage,
    method: "post",
    data: params,
  };
  return axios(getConfig)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const submitEnquiryForm = (params) => {
  const getConfig = {
    url: endpoints.enquiry,
    method: "post",
    data: params,
  };

  return axios(getConfig)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const onFavrtSave = (params, token) => {
  const getConfig = {
    url: endpoints.wishlist.updateWishList,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: params,
  };
  return axios(getConfig)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const onCustomizeDetails = (params) => {
  const getConfig = {
    url: endpoints.deatils.customization,
    method: "post",
    data: params,
  };
  return axios(getConfig)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const getWhoWeAre = () => {
  const getConfig = {
    url: endpoints.policies.whoWeAre,
    method: "get",
  };
  return axios(getConfig)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const getBlogs = () => {
  const getConfig = {
    url: endpoints.blogs.allBlogs,
    method: "get",
  };
  return axios(getConfig)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const getBookingDetails = (params, token) => {
  const getConfig = {
    url: endpoints.booking.bookingDetailsUrl,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: params,
  };
  return axios(getConfig)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const onCancelReason = () => {
  const getConfig = {
    url: endpoints.booking.cancelReason,
    method: "post",
  };
  return axios(getConfig)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const onCancelBooking = (params, token) => {
  const getConfig = {
    url: endpoints.booking.cancelBooking,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: params,
  };
  return axios(getConfig)
    .then((res) => {
      console.log(res);
      if (res.data.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};
