import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import styles from "./index.module.scss";

const Backdrop = (props) => {
  return <div style={{ zIndex: `${props.zIndex2}` }} onClick={props.onClose} className={styles.backdrop}></div>;
};

const ModalOverlay = (props) => {
  return (
    <div style={{ width: `${props.width}`, zIndex: `${props.zIndex}` }} className={styles.modal}>
      {props.children}
    </div>
  );
};

let modal = document.getElementById("modal");

const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, modal)}
      {ReactDOM.createPortal(
        <ModalOverlay width={props.width} zIndex={props.zIndex} zIndex2={props.zIndex2} className={props.className}>
          {props.children}
        </ModalOverlay>,
        modal
      )}
    </Fragment>
  );
};

export default Modal;
