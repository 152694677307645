import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { FaAngleRight } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Testimonial from "../../components/Testimonial";
import WhyChooseUs from "../../components/WhyChooseUs";
import Card from "../../components/Card";
import OfferBanner from "../../components/OfferBanner";
import { endpoints } from "../../services/endpoints";
import { useDispatch } from "react-redux";
import { uiActions } from "../../utils/reducers/ui";
import axios from "axios";
import { Skeleton } from "@mui/material";

const OffersProducts = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [offers, setOffers] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(
      uiActions.skelatonLoadingCurrently({
        loading: true,
      })
    );
    setLoading(true);
    const api = endpoints.home.productByOffer + atob(params?.offerId);
    axios
      .get(api)
      .then((res) => {
        if (res.data.status === true) {
          const val = res.data.body[0].services;
          setOffers(val);
          dispatch(
            uiActions.skelatonLoadingCurrently({
              loading: false,
            })
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        dispatch(
          uiActions.skelatonLoadingCurrently({
            loading: false,
          })
        );
        setLoading(false);
        console.log(err, "offers Api  data not found");
      });
    // eslint-disable-next-line
  }, [params?.offerId]);

  return (
    <>
      <div className={styles.allList}>
        <h1 className={styles.allList__title}>
          Home <FaAngleRight className={styles.allList__title__angle} />
          {params.offerName
            .replace(/Xperience[s]?/i, "")
            .trim()
            .replaceAll("-", " ")}
        </h1>

        <div className={styles.allList__body}>
          {loading ? (
            [1, 2, 3, 4].map((item) => {
              return <Skeleton key={item} variant="rectangular" height={350} style={{ borderRadius: "10px" }} />;
            })
          ) : (
            <>
              {offers?.map((item) => {
                return (
                  <Card
                    id={item.id}
                    key={item.id}
                    title={item.title}
                    src={item.image_id}
                    price={item.discounted_price}
                    original_price={item.outlay_price}
                    discount={item.discount_percnt}
                    data={item}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <OfferBanner />
      </div>
      <Testimonial />
      <WhyChooseUs />
    </>
  );
};

export default OffersProducts;
