import styles from "./index.module.scss";
import logo from "../../assets/logo.svg";

const CatCard = ({ item, getProductsByCategories }) => {
  return (
    <div
      onClick={() => getProductsByCategories(item)}
      title={item.name
        .replace(/Xperience[s]?/i, "")
        .trim()
        .toUpperCase()}
      className={styles.catCard}
      key={item.id}>
      <div className={styles.catCard__image}>
        <img src={item.image_id.includes("png") ? item.image_id : logo} alt={item.name} />
      </div>

      <p className={styles.catCard__name}>
        {item.name.replace(/Xperience[s]?/i, "").trim().length > 11
          ? item.name
              .replace(/Xperience[s]?/i, "")
              .trim()
              .slice(0, 11) + "..."
          : item.name.replace(/Xperience[s]?/i, "").trim()}
      </p>
    </div>
  );
};

export default CatCard;
