import React, { useEffect, useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { getFilterList } from "../../services/server";
import ProgressLoader from "../../shared/ProgressLoader";
import { generatePath, useNavigate } from "react-router-dom";

const SearchBar = ({ width }) => {
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const loc = JSON.parse(localStorage.getItem("locDet"));

  const getSubCategory = async () => {
    setLoading(true);
    const params = {
      location_id: loc.id,
    };
    const lists = await getFilterList(params);

    let allChildCat = [];
    if (lists.status) {
      lists.body.map((item) => {
        item.sub_category.map((itm) => {
          itm.child_category.map((it) => {
            allChildCat.push(it);
          });
        });
      });

      setSearchList(allChildCat);
      setFilterList(allChildCat);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSubCategory();
    // eslint-disable-next-line
  }, []);

  const filterTheList = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length > 0) {
      setShow(true);
      let val = e.target.value.toLowerCase();
      const filteredData = filterList.filter((item) => item.name.toLowerCase().startsWith(val));
      setSearchList(filteredData);
    } else {
      setShow(false);
    }
  };

  const onRedirectToLists = (list) => {
    let path = generatePath("/:location/searches/:subCatName/:subId/:childCat/:childId", {
      location: loc.city,
      subCatName: list.subcategory_nm.replaceAll(" ", "-"),
      subId: btoa(list.subcategory_id),
      childCat: list.name.replaceAll(" ", "-"),
      childId: btoa(list.id),
    });
    setShow(false);
    setSearch("");
    navigate(path);
  };

  return (
    <div
      style={{ flexDirection: `${i18n.language === "ar" ? "row-reverse" : ""}`, width: `${width}` }}
      className={styles.searchBar}>
      <input type="text" onChange={filterTheList} value={search} placeholder={`${t("search")}...`} />
      <FaMagnifyingGlass className={styles.searchBar__icon} />
      {show && (
        <div className={styles.searchBar__options}>
          {loading ? (
            <div className={styles.searchBar__options__loader}>
              <ProgressLoader color="#ffab02" />
            </div>
          ) : searchList.length < 1 ? (
            <div className={styles.searchBar__options__loader}>
              <p>No Results!</p>
            </div>
          ) : (
            <ul>
              {searchList.map((list) => {
                return (
                  <li onClick={() => onRedirectToLists(list)} key={list.id}>
                    {list.name}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
